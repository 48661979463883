import React, { useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';
import swalReact from '@sweetalert/with-react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Row,
  Table,
  FormGroup,
  InputGroup,
  Col,
  Container,
  Form,
  Input,
} from 'reactstrap';
import {
  getAccount,
  getBanks,
  validateAccount,
  saveAcount,
} from './../../../redux/actions/settlements';
import { useDispatch, useSelector } from 'react-redux';

const SettlementSetup = () => {
  const dispatch = useDispatch();
  const banks = useSelector((state) => state.settlements.Banks);
  const customerName = useSelector((state) => state.settlements.customerName);
  sessionStorage.setItem('customerName', customerName);
  const accountNumber = useSelector((state) => state.settlements.accountNumber);
  const bank = useSelector((state) => state.settlements.bank);
  const [isLoading, setIsLoading] = useState(false);
  const [bankCode, setBankCode] = useState('');
  const [validateError, setValidateError] = useState('');
  const [value, setValue] = useState('');
  const [bankName, setBankName] = useState('');

  const token = sessionStorage.getItem('token');
  const key = CryptoJS.HmacSHA512(
    sessionStorage.getItem('token'),
    '123NDSx'
  ).toString();
  const admin =
    sessionStorage.getItem('role') === 'admin' ||
    sessionStorage.getItem('role') === 'management';
  const user = sessionStorage.getItem('type');

  const formValues = {};
  formValues.token = token;
  formValues.action = 'get_banks';
  formValues.passcode = key;

  const formValues2 = {};
  formValues2.token = token;
  formValues2.action =
    user === 'service_provider' && !admin
      ? 'retrieve_agent_account'
      : 'retrieve_account';
  formValues2.passcode = key;

  useEffect(() => {
    setIsLoading(true);
    dispatch(getBanks(formValues, () => setIsLoading(false)));
    dispatch(getAccount(formValues2, () => setIsLoading(false)));
  }, [dispatch]);

  const handleBankChange = (e) => {
    const { name, value } = e.target;
    setValue(e.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBankName(e.target.selectedOptions[0].text);
    setBankCode(value);
  };

  const validateAcct = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (bankName === '0' || bankName === '') {
      setValidateError('Select a bank');
      return;
    }

    if (value === '' || value.length !== 10) {
      setValidateError('Enter a valid Account Number');
      return;
    }

    const data = {};
    data.token = token;
    data.action = 'validate_account';
    data.receiver = value;
    data.code = bankCode;
    data.passcode = key;

    dispatch(
      validateAccount(
        data,
        () => viewDetails(),
        () => setIsLoading(false)
      )
    );
  };

  const saveAccount = () => {
    swalReact.close();
    setIsLoading(true);
    const accountData = {};
    accountData.token = token;
    accountData.action =
      user === 'service_provider' && !admin
        ? 'save_agent_account'
        : 'save_account';
    accountData.receiver = value;
    accountData.code = bankCode;
    accountData.passcode = key;
    accountData.customer_name = sessionStorage.getItem('customerName');
    accountData.bank = bankName;

    dispatch(saveAcount(accountData, () => setIsLoading(false)));
  };

  const viewDetails = () => {
    swalReact({
      button: {
        text: 'Cancel',
        closeModal: true,
      },

      content: (
        <Col xl="14">
          <Card className="shadow align-innertext">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <div className="col">
                  <h3 className="mb-0">Account</h3>
                </div>
              </Row>
            </CardHeader>
            <Table className="align-items-center table-flush" responsive>
              <tbody>
                <tr>
                  <th scope="col">Customer Name</th>
                  <td>{sessionStorage.getItem('customerName')}</td>
                </tr>
                <tr>
                  <th scope="col">Account Number</th>
                  <td>{value}</td>
                </tr>

                <tr>
                  <th scope="col">Bank</th>
                  <td>{bankName}</td>
                </tr>
              </tbody>
            </Table>
          </Card>
          <Button className="btn btn-secondary" onClick={saveAccount}>
            UPDATE
          </Button>
        </Col>
      ),
    });
  };

  return (
    <div>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8"></div>
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1 mb-5 mb-xl-0" xl="6">
            <Card>
              <CardHeader>Account Details</CardHeader>
              {isLoading ? (
                <p align="center">
                  <i className="fas fa-spinner fa-spin"></i>{' '}
                </p>
              ) : (
                <Table>
                  <tbody>
                    <tr>
                      <th>Account Name</th>
                      <td>{customerName}</td>
                    </tr>

                    <tr>
                      <th>Account Number</th>
                      <td>{accountNumber}</td>
                    </tr>

                    <tr>
                      <th>Bank</th>
                      <td>{bank}</td>
                    </tr>
                  </tbody>
                </Table>
              )}
              <CardBody></CardBody>
            </Card>
          </Col>
          <Col className="order-xl-2" xl="6">
            <Card className="bg-secondary shadow border-0">
              <CardHeader>Update Account</CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                <Form onSubmit={validateAcct}>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="Account Number"
                        name="accountNumber"
                        type="text"
                        onChange={handleBankChange}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <Input
                        name="bankCode"
                        placeholder="Choose Bank"
                        type="select"
                        onChange={handleChange}
                      >
                        <option value="0">Select Bank</option>

                        {banks.map((bank) => {
                          return <option value={bank.code}>{bank.bank}</option>;
                        })}
                      </Input>
                    </InputGroup>
                  </FormGroup>

                  <p classNmae="alert-danger">{validateError}</p>

                  <Button
                    onClick={validateAcct}
                    className="my-4 text-center"
                    color="primary"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <i className="fas fa-spinner fa-spin"></i>
                    ) : (
                      'VALIDATE'
                    )}
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <div className="justify-content-center col"></div>
      </Container>
    </div>
  );
};

export default SettlementSetup;
