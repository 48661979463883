import React, { useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';
import { withRouter, Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import swalReact from '@sweetalert/with-react';
import Loader from '../Loader';
import ListPagination from '../Pagination';
import NoPaymentHistory from '../NoPaymentHistory';

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Col,
  Button,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerList, getSearchTerm, nextPage} from './../../../redux/actions/customer';

const PreviousCustomer = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const customers = useSelector((state) => state.customer.customer);
  const dataPage = useSelector((state) => state.customer.currentPage);
  const pageNum = useSelector((state) => state.customer.numberOfPages);

  const [currentPage, setCurrentPage] = useState(1);
  // const [numberOfPages, setNumberOfPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchActive, setSearchActive] = useState(true);
  const [message, setMessage] = useState('No customers found');
  const [searchedDetailActive, setSearchDetailActive] = useState(false);

  const handleNextPage = () => {
    dispatch(nextPage(dataPage + 1));
  };

  let data = {};
  const sToken = sessionStorage.getItem('token');
  const sKey = CryptoJS.HmacSHA512(
    sessionStorage.getItem('token'),
    '123NDSx'
  ).toString();
  const baseUrl =
    sessionStorage.getItem('type') === 'other' ? '/admin' : '/utility';

  const customer_ref = props.match.params.ref;
  const handleNewPage = (newPage) => {
    setLoading(true);
    dispatch(nextPage(newPage, history, () => setLoading(false)));
  };

  const handleBackPage = () => {
    setLoading(true);
    dispatch(nextPage(dataPage - 1, history, () => setLoading(false)));
  };
  useEffect(() => {
    data.passcode = sKey;
    data.token = sToken;
    data.action = 'get_customers';
    data.page = dataPage;
    dispatch(CustomerList(data, history, () => setLoading(false)));
  }, [dispatch, dataPage]);

  const formValues = {};
  formValues.passcode = sKey;
  formValues.token = sToken;
  formValues.action = 'get_customers';

  const handleViewMore = (customer) => {
    swalReact({
      content: (
        <Col xl="14">
          <Card className="shadow align-innertext">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <div className="col">
                  <h3 className="mb-0">Customer details</h3>
                </div>
              </Row>
            </CardHeader>
            <Table className="align-items-center table-flush" responsive>
              <tbody>
                <tr>
                  <th scope="col">customer_ref</th>
                  <td>{customer.customer_ref}</td>
                </tr>
                <tr>
                  <th scope="col">Account Number</th>
                  <td>{customer.account_number}</td>
                </tr>
                <th scope="col">Account Name</th>
                <td>{customer.account_name}</td>
                <tr>
                  <th scope="col">Bank Name</th>
                  <td>{customer.bank}</td>
                </tr>
                <th scope="col">Customer Name</th>
                <td>{customer.customer_name}</td>
                <tr>
                  <th scope="col">Customer Email</th>
                  <td>{customer.customer_email}</td>
                </tr>
                <th scope="col">Phone Number</th>
                <td>{customer.customer_phone}</td>
                <tr>
                  <th scope="col">Address</th>
                  <td>{customer.customer_address}</td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </Col>
      ),
    });
  };

  return loading ? (
    <Loader title="Customers List" />
  ) : !customers ? (
    <NoPaymentHistory message={message} title="Customers History" />
  ) : (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8"></div>

      <Container className="mt--9" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Previous Customers Ref:{customer_ref}</h3>
              </CardHeader>
              <Table className="align-items-center table-flush">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Utility Reference Number</th>
                    <th scope="col">Account Number</th>
                    <th scope="col">Account Name</th>
                    <th scope="col">Bank Name</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {customers &&
                    customers.length &&
                    customers.map((customer) => {
                      return (
                        <tr key={customer.customer_id}>
                          <td>{customer.customer_ref}</td>
                          <td>{customer.account_number}</td>
                          <td>{customer.account_name}</td>
                          <td>{customer.bank}</td>

                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  onClick={() => handleViewMore(customer)}
                                >
                                  <Link to={`${baseUrl}/previous-profile/${customer.customer_ref}`}>
                                    View More
                                  </Link>
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  {!searchedDetailActive && (
                    <ListPagination
                      numberOfPages={pageNum}
                      currentPage={dataPage}
                      handleBackPage={handleBackPage}
                      handleNextPage={handleNextPage}
                      handleNewPage={handleNewPage}
                    />
                  )}
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default PreviousCustomer;
