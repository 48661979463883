import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import Loader from '../Loader';
import swalReact from '@sweetalert/with-react';
import NoPaymentHistory from '../NoPaymentHistory';
import ListPagination from '../Pagination';
import {
  Badge,
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Container,
  Row,
  Col,
  CardFooter,
  Form,
  FormGroup,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { customerPayment, customerPaymentExport } from '../../../redux/actions/customer';
import ReactExport from 'react-export-excel';
import { updateCurrentPage } from '../../../redux/actions/history';
import Swal from 'sweetalert2';
import { useRef } from 'react';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const History = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const commission = useSelector((state) => state.history.commission);
  const payments = useSelector((state) => state.history.payments);
  const dataPage = useSelector((state) => state.history.currentPage);
  const pageNum = useSelector((state) => state.history.numberOfPages);
  const exportData = useSelector((state) => state.history.paymentsExport);
  const [message, setMessage] = useState('No record found');
  const [paymentLaoding, setPaymentLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');
  const [clear, setClear] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [open, setOpen] = useState(false);
  const [customer_ref, setCustomer_ref] = useState('');
  const [currentPage, setCurrentPage] = useState(1)
  const [isExportLoading, setIsExportLoading] = useState(false)
  const mountedRef = useRef()

  const type = sessionStorage.getItem('type');
  const role = sessionStorage.getItem('role');

  const handleChange = (e) => {
    setCustomer_ref(e.target.value);
  };
  const handleNextPage = () => {
    // dispatch(updateCurrentPage(dataPage + 1, history, () => {}));
    handleFetch("next")
  };

  const handleNewPage = (newPage) => {
    // dispatch(updateCurrentPage(newPage, history, () => {}));
    handleFetch(newPage)

  };

  const handleBackPage = () => {
    handleFetch("prev")
    // dispatch(updateCurrentPage(dataPage - 1, history, () => {}));
  };
  const formatAmount = (amount) => {
    return parseFloat(amount).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const toggle = () => {
    setModal(!modal);
  };
  const handleStartChange = (event) => {
    setStartDate(event.target.value);
  };
  const handleEndChange = (event) => {
    setEndDate(event.target.value);
  };

  const token = sessionStorage.getItem('token');
  const passcode = CryptoJS.HmacSHA512(token, '123NDSx').toString();

  const handleReset = ()=>{
    setCurrentPage(1)
    setClear(false)
    setCustomer_ref('')
    setEndDate('')
    setStartDate('')
  }

  const searchResult = (e) => {
    e.preventDefault();
    setClear(true);
    handleFetch('',true)
    // setPaymentLoading(true);
    // setClear(true);
    // let data = {};
    // data.token = token;
    // data.passcode = passcode;
    // data.action = 'get_payments';
    // data.customer_ref = customer_ref;
    // data.page = dataPage;
    // dispatch(customerPayment(data, history, () => setPaymentLoading(false)));
  };

  const dateSorthistory = () => {
    // let sort = {};
    // sort.passcode = passcode;
    // sort.token = token;
    // sort.page = dataPage;
    // sort.start_date = startDate;
    // sort.end_date = endDate;
    // sort.action = 'get_payments';

    // setPaymentLoading(true);
    setModal(!modal);
    setClear(true)
    handleFetch('',true)
    // dispatch(customerPayment(sort, history, () => setPaymentLoading(false)));
  };

  const handleFetch = (param, isSearch,isExport)=>{
    if(isExport){
      setIsExportLoading(true)
    }else{
      setPaymentLoading(true)
    }

    let body = {};

    if(param === "next"){
      body.page = currentPage +1
    }else if(param==="prev"){
      body.page = currentPage -1
    }else if(param && !isSearch){
      body.page = param
    }else{
      body.page = 1
    }

    body.passcode = passcode;
    body.token = token;
    body.start_date = startDate;
    body.end_date = endDate;
    body.customer_ref = customer_ref;
    body.action = 'get_payments';
    body.paginated = "yes"

    if(isExport){
      body.paginated = "no"
      dispatch(customerPaymentExport(body, history, () => {
        setIsExportLoading(false)
      }))
      return
    }

    dispatch(customerPayment(body, history, () => {
      setPaymentLoading(false)
    }));

  }

  const handleExport = ()=>{
    handleFetch('','',true)
  }

  useEffect(()=>{
    setCurrentPage(dataPage)
  },[dataPage])

  useEffect(()=>{
    if(startDate || endDate || customer_ref || currentPage !== 1) return
    handleFetch()
  },[currentPage,startDate,endDate,customer_ref])

  useEffect(() => {
    if(!mountedRef.current){
      mountedRef.current = true
      return
    }

    if (exportData && exportData.length) {
      const btn = document.getElementById('export_btn')
      setTimeout(()=>{
         btn && btn.click()
      },1000) 
    }
    if(exportData && exportData.length===0){
      Swal.fire({
        icon: 'info',
        title: 'Oops',
        text: 'No data to export',
      })
    }
  }, [exportData]);

  // useEffect(() => {
  //   let data = {};
  //   data.action = 'get_payments';
  //   data.token = token;
  //   data.page = isMounted ? dataPage : 1;
  //   data.passcode = passcode;
  //   setPaymentLoading(true);
  //   dispatch(customerPayment(data, history, () => {
  //     !isMounted && setIsMounted(true)
  //     setPaymentLoading(false)
  //   }));
  // }, [dispatch, dataPage]);

  const handleViewMore = (payment) => {
    swalReact({
      content: (
        <Col xl="14">
          <Card className="shadow align-innertext">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <div className="col">
                  <h3 className="mb-0">Payment Details</h3>
                </div>
                <div className="col text-right">
                  <Badge color="success">Fulfilled</Badge>
                </div>
              </Row>
            </CardHeader>
            <Table className="align-items-center table-flush" responsive>
              <tbody>
                <tr>
                  <th scope="row">Bank</th>
                  <td>{payment.bank}</td>
                </tr>
                <tr>
                  <th scope="row">Account Number</th>
                  <td>{payment.account_number}</td>
                </tr>
                <tr>
                  <th scope="row">Payment Ref</th>
                  <td>{payment.payment_ref}</td>
                </tr>
                {type === 'service_provider' && (
                  <tr>
                    <th scope="row">Utility Ref</th>
                    <td>{payment.utility_ref}</td>
                  </tr>
                )}
                <tr>
                  <th scope="row">Transaction ID</th>
                  <td>{payment.transaction_id}</td>
                </tr>
                <tr>
                  <th scope="row">Amount Paid</th>
                  <td>{formatAmount(payment.amount)}</td>
                </tr>

                <tr>
                  <th scope="row">Amount Dispensed</th>
                  <td>{formatAmount(payment.amount_dispense)}</td>
                </tr>

                <tr>
                  <th scope="row">Service Charge</th>
                  <td>{formatAmount(payment.service_charge)}</td>
                </tr>

                <tr>
                  <th scope="row">Payment ID</th>
                  <td>{payment.payment_id}</td>
                </tr>

                <tr>
                  <th scope="row">Payment Mode</th>
                  <td>{payment.source}</td>
                </tr>
                <tr>
                  <th scope="row">Timestamp</th>
                  <td>{payment.timestamp}</td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </Col>
      ),
    });
  };

  return paymentLaoding ? (
    <Loader title="Payment History" />
  ) : !payments ? (
    <NoPaymentHistory message={message} title="Payment History" />
  ) : (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8"></div>

      <Container className="mt--9" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Payment History</h3>

              </CardHeader>
              {type === 'service_provider' && role === 'management' && (
                <Badge
                  className="mr-5 w-25 d-flex justify-content-center ml-lg-auto"
                  color="info"
                >
                  Commission Earned: ₦ {formatAmount(commission)}
                </Badge>
              )}

              <div>
                <Form
                  onSubmit={searchResult}
                  className="navbar-search navbar-search-light form-inline mr-3 d-none d-md-flex ml-lg-auto"
                >
                  <FormGroup className="m-4">
                    <Input
                      type="text"
                      name="customer_reference"
                      id="customer_reference"
                      placeholder="Utility reference or Account number"
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <Button
                    style={{
                      marginRight: '15px',
                      height: '43px',
                      margiRight: '15px',
                      marginLeft: '7px',
                    }}
                    // color="primary"
                    onClick={searchResult}
                  >
                    Search
                  </Button>

                  {clear && (
                    <Button
                      style={{
                        marginRight: '15px',
                        height: '43px',
                        margiRight: '15px',
                        marginLeft: '7px',
                      }}
                      // onClick={searchResult}
                      onClick={handleReset}
                    >
                      Show All
                    </Button>
                  )}
                  <div className="d-flex justify-content-end ml-lg-auto">
                    <Button color="primary" onClick={toggle}>
                      Sort By Date
                    </Button>
                    <Modal isOpen={modal} toggle={toggle}>
                      {/* <ModalHeader toggle={this.toggle}>Select Date Range Below</ModalHeader> */}
                      <ModalBody>
                        <Form>
                          <FormGroup>
                            Enter Start Date
                            <Input
                              type="date"
                              id="startDate"
                              name="startDate"
                              onChange={handleStartChange}
                            />
                          </FormGroup>
                          <FormGroup>
                            Enter End Date
                            <Input
                              type="date"
                              name="endDate"
                              id="endDate"
                              onChange={handleEndChange}
                            />
                          </FormGroup>
                        </Form>
                      </ModalBody>
                      <ModalFooter>
                        <Button color="primary" onClick={dateSorthistory}>
                          Sort
                        </Button>
                        <Button color="danger" onClick={toggle}>
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                    <ExcelFile
                      filename="Payment History"
                      element={
                        <button 
                        className="d-none"
                        id="export_btn"
                         >
                          {/* {isExportLoading? <i className="fas fa-spinner fa-spin"></i>:<i className="fas fa-download" />} */}
                          Export
                        </button>
                      }
                    >
                      <ExcelSheet data={exportData} name="Payment History">
                        <ExcelColumn
                          label="Account Name"
                          value="account_name"
                        />
                        <ExcelColumn
                          label="Amount Dispensed"
                          value="amount_dispense"
                        />
                        <ExcelColumn
                          label="Payment Reference"
                          value="payment_ref"
                        />
                        <ExcelColumn
                          label="Utility Reference"
                          value="utility_ref"
                        />
                        <ExcelColumn
                          label="Transaction ID"
                          value="transaction_id"
                        />
                        <ExcelColumn label="Time Stamp" value="timestamp" />
                      </ExcelSheet>
                    </ExcelFile>
                    <Button color="success" className="mr-3" onClick={handleExport} disabled={isExportLoading}>
                      {isExportLoading? <i className="fas fa-spinner fa-spin"></i>:<i className="fas fa-download" />}
                        Export
                      </Button>
                  </div>
                </Form>
                <div>
                    <p className='px-4 small'>{(startDate || endDate || customer_ref) &&
                  `Sort Parameter(s): ${startDate||endDate? 'Date':''}${(startDate||endDate) &&
                    customer_ref && ', '} ${customer_ref?'Reference':''}`}</p>
                </div>
              </div>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Account Name</th>
                    <th scope="col">Amount Dispensed</th>
                    <th scope="col">Payment Reference</th>
                    {type === 'service_provider' && (
                      <th scope="col">Utility Reference</th>
                    )}
                    {type === 'service_provider' && role === 'agent' && (
                      <th scope="col">Commission</th>
                    )}
                    <th scope="col">Transaction ID</th>
                    <th scope="col">Time stamp</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {payments.map((payment, index) => {
                    return (
                      <tr key={index}>
                        <th scope="row">{payment.account_name}</th>

                        <td>{formatAmount(payment.amount_dispense)}</td>
                        <td>{payment.payment_ref}</td>
                        {type === 'service_provider' && (
                          <td>{payment.utility_ref}</td>
                        )}
                        {type === 'service_provider' && role === 'agent' && (
                          <td>{payment.service_charge}</td>
                        )}
                        <td>{payment.transaction_id}</td>
                        <td>{payment.timestamp}</td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                onClick={() => handleViewMore(payment)}
                              >
                                View More
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <ListPagination
                    numberOfPages={pageNum}
                    currentPage={dataPage}
                    // currentPage={currentPage}
                    handleBackPage={handleBackPage}
                    handleNextPage={handleNextPage}
                    handleNewPage={handleNewPage}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default History;
