import React from 'react';
import { Button } from 'reactstrap';

const PageNotFound = ({history}) => {
    return(
        <>
        <div className="page-not-found">
         <div className="not-found-image">
             <div className="notfound-logo"></div>

         </div>
         <div className="not-found-text">
         
        <Button color="primary"  onClick={() => {history.push('/') }}>BACK TO HOMEPAGE</Button>
                   
         </div>

        </div>
        </>

    )
}

export default PageNotFound;
