
import React, { useState, useEffect } from "react";
import CryptoJS from "crypto-js";
import Swal from 'sweetalert2';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
  Progress
} from "reactstrap";
import { withFormik, Form as FormikForm, Field } from 'formik';
import * as Yup from 'yup'
import deepEqual from 'lodash.isequal';


const RegisterCustomer = ({
  initialValues,
  values,
  errors,
  touched,
  dirty,
  isSubmitting,
  history

}) => {

  const [progress, setProgress] = useState(0);

  const hasChanged = deepEqual(values, initialValues);
  const hasErrors = Object.keys(errors).length > 0;


  useEffect(() => {
    const errorSet = new Set(Object.keys(errors));
    const touchSet = new Set(Object.keys(touched));

    function difference(setA, setB) {
      let _difference = new Set(setA)
      for (let elem of setB) {
        _difference.delete(elem)
      }
      return _difference
    }

    setProgress(difference(touchSet, errorSet).size)

  });



  return (
    <>

      <div className="header bg-gradient-info pb-8 pt-5 pt-md-6"></div>
      {/* Page content */}
      <Container className=" mt--8 pb-5" fluid="xl">

        <div className="justify-content-center col">
          <Col className="col-sm-12  col-md-7 offset-md-2" fluid>
            <Card className="bg-secondary shadow border-0">

              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <h2>Register Customer</h2>
                  <Progress animated value={progress * 20} />
                </div>
                <FormikForm autoComplete="off">

                  {touched.customerName && errors.customerName && <small className="error-message">{errors.customerName}</small>}
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-circle-08" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Field placeholder="FullName*" type="text" name="customerName" className="form-control" />
                    </InputGroup>
                  </FormGroup>


                  {touched.customerReference && errors.customerReference && <small className="error-message">{errors.customerReference}</small>}
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-bulb-61" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Field placeholder="Customer Reference*" type="text" name="customerReference" className="form-control" />
                    </InputGroup>
                  </FormGroup>

                  {touched.phone && errors.phone && <small className="error-message">{errors.phone}</small>}
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-mobile-button" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Field placeholder="Primary Phone Number*" type="tel" name="phone" className="form-control" />
                    </InputGroup>
                  </FormGroup>
                  
                  <Row>
                    <Col className="md-6">
                      <FormGroup>
                        <InputGroup className="input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-mobile-button" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Field placeholder="Second Phone Number" type="tel" name="phone1" className="form-control" />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col className="md-6">
                      <FormGroup>
                        <InputGroup className="input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-mobile-button" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Field placeholder="Third Phone Number" type="tel" name="phone2" className="form-control" />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>

                  {touched.address && errors.address && <small className="error-message">{errors.address}</small>}
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-world-2" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Field placeholder="Address*" type="text" name="address" className="form-control" />
                    </InputGroup>
                  </FormGroup>


                  {touched.email && errors.email && <small className="error-message">{errors.email}</small>}
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Field placeholder="Email*" type="email" name="email" className="form-control" />
                    </InputGroup>
                  </FormGroup>
                  <Row className="my-4">

                  </Row>
                  <div className="text-center">
                    <Button className="mt-4" type="submit" color="primary" disabled={hasChanged || hasErrors || isSubmitting}>
                      {isSubmitting ? <span><i className="fas fa-spinner fa-spin"></i>Please wait...</span> : 'Create Account'}
                    </Button>
                  </div>
                </FormikForm>
              </CardBody>
            </Card>
          </Col>
        </div>

      </Container>
    </>
  );
}


const CustomerForm = withFormik({
  mapPropsToValues({ email, history, customerName, phone, phone1, phone2, address, customerReference }) {
    return {
      email: '',
      customerName: '',
      phone: '',
      phone1: '',
      phone2: '',
      address: '',
      customerReference: '',
      history
    }
  },
  validationSchema: Yup.object().shape({
    email: Yup.string().email('Email must be valid').required('Email is required'),
    customerName: Yup.string('Invalid customer name').required('Customer name is required').min(3, 'Please provide your fullname'),
    phone: Yup.string('Invalid Phone Number').required('Phone number is required'),
    phone1: Yup.number('Invalid Phone Number'),
    phone2: Yup.number('Invalid Phone Number'),
    address: Yup.string().required('Address is required'),
    customerReference: Yup.string().required('Customer Reference is required')
  }),
  async handleSubmit(values, { resetForm, setErrors, setSubmitting, props }) {


    try {
      console.log(values, 'values');

      const token = sessionStorage.getItem('token');

      const passcode = CryptoJS.HmacSHA512(token, "123NDSx").toString();

      const data = {

        token,
        action: "register",
        customer_name: values.customerName,
        customer_phone: values.phone,
        customer_phone1: values.phone1,
        customer_phone2: values.phone2,
        customer_email: values.email,
        customer_address: values.address,
        customer_ref: values.customerReference,
        passcode

      }

      const response = await fetch('https://irecharge.com.ng/api/adept/register_customer.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })

      const responseData = await response.json()


      if (responseData.status === '0') {

        sessionStorage.setItem("createdAccount", JSON.stringify(responseData));
        resetForm();
        setSubmitting(false);
        return props.history.push('/admin/account-result');

      } else if (responseData.status === '-2') {
        return Swal.fire({
          icon: 'info',
          title: responseData.message,
          text: 'Please login again',
          timer: 2000,
          onClose: () => {
            props.history.push('/auth/login')
          }
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            props.history.push('/auth/login')
          }
        })
      }
      else {
        return Swal.fire({
          icon: 'info',
          text: responseData.message
        })
      }
    } catch (err) {
      return Swal.fire({
        icon: 'error',
        title: 'Registration Failed',
        text: 'Unable to register customer'
      })

    }

  }
})(RegisterCustomer);

export default CustomerForm;
