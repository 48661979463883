import React, { useState, useEffect } from "react";
import CryptoJS from 'crypto-js';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
import { useDispatch, useSelector } from 'react-redux';
import { getUser, updateUser, clearUpdateField } from './../../../redux/actions/user';
import { useHistory } from 'react-router-dom';

const UserUpdate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const token = sessionStorage.getItem('token');
  const passcode = CryptoJS.HmacSHA512(token, '123NDSx').toString();
  const dispatch = useDispatch();
  const history = useHistory();
  const userEmail = useSelector((state) => state.user.user_email);
  const userPhone = useSelector((state) => state.user.user_phone);
  const userName = useSelector((state) => state.user.user_name);
  const userId = useSelector((state) => state.user.user_id);
  const userRole = useSelector((state) => state.user.user_role);
  const [value, setValue] = useState({
    userName: '',
    userEmail: '',
    userPhone: '',
    userRole:'',
    userId:''
  });

  useEffect(() => {
    setIsLoading(true)
    const { id } = props.match.params;
    const data = {
      token,
      action: 'get_user',
     user_id: id,
      passcode: passcode
    }
    dispatch(
      getUser(data, history, () =>  setIsLoading(false))
    );
    return () => {
      dispatch(clearUpdateField())
    }
  }, [dispatch]);

  const handleSumbit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = {
      action: 'edit_user',
      passcode,
      token,
     user_email: value.userEmail !== "" ? value.userEmail :userEmail,
     user_name: value.userName !== "" ? value.userName :userName,
     user_phone: value.userPhone !== "" ? value.userPhone :userPhone,
     user_id: value.user_id !== "" ? value.user_id :userId,
     user_role: value.userRole !== "" ? value.userRole :userRole,
    }
    dispatch(updateUser(data, history, () => setIsLoading(false)));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValue((prevState) => ({
      ...prevState,
      [name]: value,
      user_id: userId,
      userRole
    }));
  }

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setValue((prevState) => ({
      ...prevState,
      userRole: value
    }));
  }

  return (
    <>
      {/* <UserHeader /> */}
      <div
        className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        style={{
          minHeight: "300px"
        }}
      >
        {/* Mask */}
        <span className="mask bg-gradient-default opacity-8" />
        {/* Header container */}

      </div>


      {/* Page content */}
      <Container className="mt--7" fluid>
            <Row>
              <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
                <Card className="card-Customer shadow">
                  <CardBody className="pt-0 pt-md-4">
                    <Row>
                    </Row>
                    <div className="text-center">
                      <h3>
                        {userName}
                        <span className="font-weight-light"></span>
                      </h3>
                      <div className="h5 font-weight-300">
                        <i className="ni location_pin mr-2" />
                        {userRole}
                      </div>
                      <div className="h5 font-weight-300">
                        <i className="ni location_pin mr-2" />
                        {userEmail}
                      </div>
                      <div className="h5 font-weight-300">
                        <i className="ni location_pin mr-2" />
                        {userPhone}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col className="order-xl-1" xl="8">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Edit User Details</h3>
                      </Col>
                      <Col className="text-right" xs="4">
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        User information
                    </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Name
                            </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={userName}
                                id="input-name"
                                placeholder={userName}
                                type="text"
                                name="userName"
                                onChange={handleChange}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-last-name"
                              >
                                Phone Number
                            </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={userPhone}
                                id="input-phone"
                                placeholder={userPhone}
                                type="text"
                                name="userPhone"
                                onChange={handleChange}
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Email address
                            </label>
                              <Input
                                className="form-control-alternative"
                                id="input-email"
                                placeholder={userEmail}
                                type="email"
                                name="userEmail"
                                onChange={handleChange}
                              />
                            </FormGroup>
                          </Col>

                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                User Role
                            </label>
                              <Input as="select"
                                className="form-control-alternative"
                                id="role"
                                placeholder={userRole}
                                type="select"
                                name="userRole"
                                onChange={handleSelectChange}
                              >
                               <option value="admin" selected={userRole=='admin'}>Admin</option>
                                <option value="representative" selected={userRole=='representative'}>Representative</option>
                              </Input>

                            </FormGroup>
                          </Col>
                        </Row>
                        <Container className="d-flex align-items-center" fluid>
                          <Row>
                            <Col lg="7" md="10">
                              <Button
                                color="info"
                                onClick={handleSumbit}
                                disabled={isLoading}
                              >
                                {isLoading ? <span><i className="fas fa-spinner fa-spin"></i>Please wait...</span> : 'Update Changes'}
                              </Button>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
    </>
  );
};

export default UserUpdate;

