import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media
} from "reactstrap";

class AdminNavbar extends React.Component {
  state = {
    baseUrl: sessionStorage.getItem('type') === 'other' ? '/admin' : '/utility',
  }
  render() {
    const name = sessionStorage.getItem('company_name');
    const adminLogo = sessionStorage.getItem('company_logo');
    let companyLogo;
    adminLogo === "" ? companyLogo = require("assets/img/theme/avatar.png") : companyLogo = adminLogo;
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <Link
              className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
              to={this.props.location.pathname}
            >
              {this.props.brandText}
            </Link>
            {/* <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
              <FormGroup className="mb-0">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Search" type="text" />
                </InputGroup>
              </FormGroup>
            </Form> */}
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={companyLogo}
                      />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {name}    <i className="ni ni-bold-down" />
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Welcome!</h6>
                  </DropdownItem>
                  {
                    sessionStorage.getItem("role") !== 'representative' ?
                      <DropdownItem to={`${this.state.baseUrl}/settlements`} tag={Link}>
                        <i className="ni ni-settings-gear-65" />
                        <span>Settlements</span>
                      </DropdownItem>
                    : ''
                  }
                  <DropdownItem to={`${this.state.baseUrl}/reset-password`} tag={Link}>
                    <i className="ni ni-support-16" />
                    <span>Reset Password</span>
                  </DropdownItem>

                  <DropdownItem divider />

                  <DropdownItem  onClick={() => {
                  sessionStorage.clear();
                  this.props.history.push('/auth/login');
                }}>
                    <i className="ni ni-button-power" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AdminNavbar;
