import React from 'react';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import AdminLayout from './layouts/Admin.jsx';
import AuthLayout from './layouts/Auth.jsx';
import Utility from './layouts/Utility';
import PageNotFound from './views/pages/PageNotFound';
import store from './redux/Store';
export const history = createBrowserHistory();

const App = () => {
  return (
    <div>
      <HashRouter history={history}>
        <Provider store={store}>
          <Switch>
            <Redirect exact from="/" to="/auth/login" />
            <Route
              path="/admin"
              render={(props) => <AdminLayout {...props} />}
            />
            <Route path="/utility" render={(props) => <Utility {...props} />} />
            <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
            <Route
              path="/notfound"
              render={(props) => <PageNotFound {...props} />}
            />
            <Route path="*">
              <Redirect to="/notfound" />
            </Route>
          </Switch>
        </Provider>
      </HashRouter>
      ,
    </div>
  );
};

export default App;
