import {
    AGENT_LIST,
    REGISTER_AGENT, SAVE_AGENT, CLEAR_AGENT, UPDATE_CURRENT_PAGE
  } from '../actions/actionTypes';
  const initialState = {
    isLoading: false,
    searchTerm: '',
    numberOfPages: 1,
    currentPage: 1,
    message: 'No agents found',
    searchedDetailActive: false,
    searchActive: false,
    baseUrl: sessionStorage.getItem('type') === 'other' ? '/admin' : '/utility',
    aResult: [],
    agent_name: '',
    agent_email: '',
    agent_phone: '',
    agent_id: ''
  };
  export const AgentReducer = (state = initialState, action) => {
    switch (action.type) {
      case AGENT_LIST:
        return {
          ...state,
          searchActive: false,
          aResult: action.items,
          currentPage: action.current_page,
          numberOfPages: action.total_pages,
          isLoading: false,
        };
      case REGISTER_AGENT:
        return {
          ...state,
          aResult: action.agent,
          isLoading: false,
        };
      case SAVE_AGENT:
        return {
          ...state,
          agent_name: action.agent.agent_name,
          agent_email: action.agent.agent_email,
          agent_phone: action.agent.agent_phone,
          agent_id: action.agent.agent_id
        };
      case CLEAR_AGENT:
        return {
          ...state,
          agent_name: "",
          agent_email: "",
          agent_phone: "",
          agent_id: ""
        };
      case UPDATE_CURRENT_PAGE:
        return {
          ...state,
          currentPage: action.current_page
        }
      default:
        return state;
    }
  };