import { combineReducers } from 'redux';
import { AuthReducer } from './auth';
import { CustomerReducer } from './customer';
import { AgentReducer} from './agent';
import { EditCustomerReducer } from './customerProfile';
import { HistoryReducer } from './history';
import { SettlementReducer } from './settlementsReducer';
import { UserReducer } from './user';

export default combineReducers({
  auth: AuthReducer,
  customer: CustomerReducer,
  customerProfile: EditCustomerReducer,
  history: HistoryReducer,
  settlements: SettlementReducer,
  agent:AgentReducer,
  user:UserReducer

});
