import React, { useState, useEffect } from "react";
import CryptoJS from "crypto-js";
import Swal from "sweetalert2";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Container,
  Progress
} from "reactstrap";
import { withFormik, Form as FormikForm, Field } from "formik";
import * as Yup from "yup";
import deepEqual from "lodash.isequal";

const getCustomerRef = state => {
  return state ? state.customer_ref : "";
};

const NewCustomerUpdate = ({
  initialValues,
  values,
  errors,
  touched,
  isSubmitting,
  location: { state }
}) => {
  const [progress, setProgress] = useState(0);

  const hasChanged = deepEqual(values, initialValues);
  const hasErrors = Object.keys(errors).length > 0;

  useEffect(() => {
    const errorSet = new Set(Object.keys(errors));
    const touchSet = new Set(Object.keys(touched));

    function difference(setA, setB) {
      let _difference = new Set(setA);
      for (let elem of setB) {
        _difference.delete(elem);
      }
      return _difference;
    }

    setProgress(difference(touchSet, errorSet).size);
  });

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8"></div>
      {/* Page content */}
      <Container className=" mt--8 pb-5" fluid="xl">
        <div className="justify-content-center col">
          <Col className="col-sm-12  col-md-7 offset-md-2" fluid>
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <h2>Update Customer Details</h2>
                  <Progress animated value={progress * 16.7} />
                </div>
                <FormikForm autoComplete="off">
                  {touched.customer_name && errors.customer_name && (
                    <small className="error-message">
                      {errors.customer_name}
                    </small>
                  )}
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-circle-08" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Field
                        placeholder="FullName*"
                        type="text"
                        name="customer_name"
                        className="form-control"
                      />
                    </InputGroup>
                  </FormGroup>

                  {touched.customer_ref && errors.customer_ref && (
                    <small className="error-message">
                      {errors.customer_ref}
                    </small>
                  )}
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-bulb-61" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Field
                        placeholder="Reference is unavailable, Please go back to select a Customer"
                        type="text"
                        name="customer_ref"
                        className="form-control"
                        value={getCustomerRef(state)}
                        readOnly
                      />
                    </InputGroup>
                  </FormGroup>

                  {touched.customer_phone && errors.customer_phone && (
                    <small className="error-message">
                      {errors.customer_phone}
                    </small>
                  )}
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-mobile-button" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Field
                        placeholder="Phone Number*"
                        type="tel"
                        name="customer_phone"
                        className="form-control"
                      />
                    </InputGroup>
                  </FormGroup>

                  
                  {touched.customer_address && errors.customer_address && (
                    <small className="error-message">
                      {errors.customer_address}
                    </small>
                  )}
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-world-2" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Field
                        placeholder="Address*"
                        type="text"
                        name="customer_address"
                        className="form-control"
                      />
                    </InputGroup>
                  </FormGroup>

                  {touched.customer_email && errors.customer_email && (
                    <small className="error-message">
                      {errors.customer_email}
                    </small>
                  )}
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Field
                        placeholder="Email*"
                        type="email"
                        name="customer_email"
                        className="form-control"
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button
                      className="mt-4"
                      type="submit"
                      color="primary"
                      disabled={hasChanged || hasErrors || isSubmitting}
                    >
                      {isSubmitting ? (
                        <span>
                          <i className="fas fa-spinner fa-spin"></i>Please
                          wait...
                        </span>
                      ) : (
                        "Update Details"
                      )}
                    </Button>
                  </div>
                </FormikForm>
              </CardBody>
            </Card>
          </Col>
        </div>
      </Container>
    </>
  );
};

const CustomerForm = withFormik({
  mapPropsToValues({ history, location: { state } }) {
    return {
      customer_email: "",
      customer_name: "",
      customer_phone: "",
      customer_phone1: "",
      customer_phone2: "",
      customer_address: "",
      customer_ref: getCustomerRef(state),
      history
    };
  },
  validationSchema: Yup.object().shape({
    customer_email: Yup.string()
      .email("Email must be valid")
      .required("Email is required"),
    customer_name: Yup.string("Invalid customer name")
      .required("Customer name is required")
      .min(3, "Please provide your fullname"),
    customer_phone: Yup.string()
      .min(11, "Phone number digits incomplete")
      .required("Phone number is required"),
    
    customer_address: Yup.string().required("Address is required"),
    customer_ref: Yup.string().required("The customer reference is invalid")
  }),
  async handleSubmit(values, { resetForm, setErrors, setSubmitting, props }) {
    try {
      const token = sessionStorage.getItem("token");
      const baseUrl = sessionStorage.getItem('type') === 'other' ? '/admin' : '/utility'

      const passcode = CryptoJS.HmacSHA512(token, "123NDSx").toString();

      const data = {
        token,
        action: "register_additional_customer",
        customer_name: values.customer_name,
        customer_phone: values.customer_phone,
        customer_email: values.customer_email,
        customer_address: values.customer_address,
        customer_ref: values.customer_ref,
        passcode,
        is_active: true
      };

      const response = await fetch(
        "https://irecharge.com.ng/api/adept/register_additional_customer_sp.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(data)
        }
      );

      const responseData = await response.json();

      if (responseData.status === "0") {
        sessionStorage.setItem("updatedAccount", JSON.stringify(responseData));
        resetForm();
        setSubmitting(false);
        return props.history.push(`${baseUrl}/update-result`);
      } else if (responseData.status === "-2") {
        return Swal.fire({
          icon: "info",
          title: responseData.message,
          text: "Please login again",
          timer: 2000,
          onClose: () => {
            props.history.push("/auth/login");
          }
        }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
            props.history.push("/auth/login");
          }
        });
      } else {
        return Swal.fire({
          icon: "info",
          text: responseData.message
        });
      }
    } catch (err) {
      return Swal.fire({
        icon: "error",
        title: "Update Failed",
        text: "Unable to update customer details"
      });
    }
  }
})(NewCustomerUpdate);

export default CustomerForm;
