import { AGENT_LIST, START_LOADING, REGISTER_AGENT, CLEAR_AGENT, SAVE_AGENT, UPDATE_CURRENT_PAGE } from './actionTypes';
import Swal from 'sweetalert2';
import callApi from '../../utilitySevice/api';
import { handleError } from '../../utilitySevice/apiError';

export const startLoading = () => {
  return {
    type: START_LOADING,
  };
};
export const updateCurrentPage = (data, history, cb) => (dispatch) => {
  dispatch({
    type: UPDATE_CURRENT_PAGE,
    current_page: data
  })
  cb();
};
export const AgentList = (data, history, cb) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const res = await callApi('/get_agents.php', data, 'POST');
    if (res.status === "0") {
      const { items, current_page, total_pages } = res;
      dispatch({
        type: AGENT_LIST,
        aResult: res,
        items,
        current_page,
        total_pages,
      })
      cb();
    } else {
      cb();
      handleError(res, history)
    }
  } catch (error) {
    cb();
    Swal.fire({
      icon: 'error',
      title: 'Unable to load agents',
      text: 'Please check your internet and try again or contact support',
      position: 'center',
    });
  }
};

export const AgentCreate = (data, history, cb) => async (dispatch) => {
  try {
    const res = await callApi('/register_agent.php', data, 'POST');
    if (res.status === "0") {
      dispatch({ type: REGISTER_AGENT, agent: res });
      let type = sessionStorage.getItem('type');
      if (type == 'service_provider') {
        history.push("/utility/agents");
      } else {
        history.push("/admin/agents");
      }
    } else {
      cb();
      handleError(res, history)
    }
  } catch (err) {
    return Swal.fire({
      icon: "error",
      title: "Update Failed",
      text: "Unable to update customer details"
    });
  }
};

export const clearUpdateField = () => {
  return {
    type: CLEAR_AGENT
  };
};

export const updateAgent = (data, history, cb) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const res = await callApi('/edit_agent.php', data, 'POST');
    if (res) {
      Swal.fire({
        icon: 'success',
        text: 'Agent updated successfully',
        showClass: {
          popup: 'animated fadeInDown faster'
        },
        hideClass: {
          popup: 'animated fadeOutUp faster'
        }
      });
      let type = sessionStorage.getItem('type');
      if (type == 'service_provider') {
        history.push("/utility/agents");
      } else {
        history.push("/admin/agents");
      }
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'Update Failed',
      text: 'Unable to update agent details',
    });
  }
};
export const getAgent = (data, history, cb) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const res = await callApi('/get_agent.php', data, 'POST');
    if (res) {
        dispatch({
          type: SAVE_AGENT,
          agent: res,
        })
    }
    cb();
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'Update Failed',
      text: 'Unable to update agent details',
    });
  }
};
