export const LOGIN = 'LOGIN';
export const REGISTER = 'REGISTER';
export const LOGOUT = 'LOGOUT';
export const END_AUTH_LOADING = 'END_AUTH_LOADING';
export const START_AUTH_LOADING = 'START_AUTH_LOADING';
export const CLEAR_DATA = 'CLEAR_DATA';
export const GET_USER = 'GET_USER';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const CLEAR_FEEDBACK = 'CLEAR_FEEDBACK';
export const SHOW_FEEDBACK = 'SHOW_FEEDBACK';
export const UPDATE_CURRENT_PAGE = 'UPDATE_CURRENT_PAGE';

// Customer Actions
export const REGISTER_CUSTOMER = 'REGISTER_CUSTOMER';
export const CUSTOMERS_LIST = 'CUSTOMERS_LIST';
export const VIEW_CUSTOMER = 'VIEW_CUSTOMER';
export const EDIT_CUSTOMER = 'EDIT_CUSTOMER';
export const UPDATE_CUSTOMER_DETAILS = 'UPDATE_CUSTOMER_DETAILS';
export const PREVIOUS_CUSTOMER = 'PREVIOUS_CUSTOMER';
export const CUSTOMER_PAYMENT_HISTORY = 'CUSTOMER_PAYMENT_HISTORY';
export const CUSTOMER_PAYMENT_HISTORY_EXPORT = 'CUSTOMER_PAYMENT_HISTORY_EXPORT';
export const SAVE_CUSTOMER = 'SAVE_CUSTOMER';
export const CLEAR_CUSTOMER = 'CLEAR_CUSTOMER';

// Agent Actions
export const REGISTER_AGENT = 'REGISTER_AGENT';
export const AGENT_LIST = 'AGENT_LIST';
export const EDIT_AGENT = 'EDIT_AGENT';
export const SEARCH_AGENT = 'SEARCH_AGENT';
export const START_LOADING = 'START_LOADING';
export const SAVE_AGENT = 'SAVE_AGENT';
export const CLEAR_AGENT = 'CLEAR_AGENT';
// User Actions
export const REGISTER_USER = 'REGISTER_USER';
export const USER_LIST = 'USER_LIST';
export const SAVE_USER = 'SAVE_USER';
export const CLEAR_USER = 'CLEAR_USER';


// HISTORY ACTIONS
export const GET_ALL_PAYMENT_HISTORY = 'GET_ALL_PAYMENT_HISTORY';
export const VIEW_PAYMENT = 'VIEW_PAYMENT';
export const GET_DATE_SORT_SETTLEMENTS = ' GET_DATE_SORT_SETTLEMENTS';
export const GET_SETTLEMENTS = 'GET_SETTLEMENTS';
export const GET_SETTLEMENTS_EXPORT = 'GET_SETTLEMENTS_EXPORT';


// SETTLEMENTS
export const SAVE_ACCOUNT = 'SAVE_ACCOUNT';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const GET_BANKS = 'GET_BANKS';
export const VALIDATE_ACCOUNT = "VALIDATE_ACCOUNT";
export const GET_ACCOUNT = "GET_ACCOUNT";

// DASHBOARD
export const DASHBOARD = 'DASHBOARD';


//PAGINATION
export const NEXT_PAGE = "CURRENT_PAGE";
export const PREVIOUS_PAGE = "PREVIOUS_PAGE";