import {
  EDIT_CUSTOMER,
  START_AUTH_LOADING,
  END_AUTH_LOADING,
} from './actionTypes';
import Swal from 'sweetalert2';
import callApi from './../../utilitySevice/api';

export const EditCustomerProfile = (data, history, cb) => async (dispatch) => {
  dispatch({
    type: START_AUTH_LOADING,
  });
  try {
    const res = await callApi('/get_customer.php', data, 'POST');

    if (res) {
      dispatch(
        dispatch({
          type: EDIT_CUSTOMER,
          customer: res,
        })
      );
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: error.message,
      position: 'center',
    });
  } finally {
    cb();
  }
};

export const saveEditedProfile = (data, history, cb) => async (dispatch) => {
  try {
    const res = await callApi('/edit_customer.php', data, 'POST');
  
    if (res) {
      dispatch(dispatch({ type: EDIT_CUSTOMER, customer: res }));
      Swal.fire({
        icon: 'success',
        text: 'Customer updated successfully',
        showClass: {
          popup: 'animated fadeInDown faster'
        },
        hideClass: {
          popup: 'animated fadeOutUp faster'
        }
      })
      history.push('/utility/customers');
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'error.message',
      text: "Unable to edit customer's profile",
    });
  } finally {
    cb();
  }
};

