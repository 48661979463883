
import React, { useState, useEffect } from "react";
import CryptoJS from "crypto-js";
import Swal from 'sweetalert2';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
  Progress,
} from "reactstrap";
import { withFormik, Form as FormikForm, Field } from 'formik';
import * as Yup from 'yup'
import deepEqual from 'lodash.isequal';


const RegisterCustomer = ({
  initialValues,
  values,
  errors,
  touched,
  dirty,
  isSubmitting,
  history

}) => {

  const [progress, setProgress] = useState(0);

  const hasChanged = deepEqual(values, initialValues);
  const hasErrors = Object.keys(errors).length > 0;
  const categories = JSON.parse(sessionStorage.getItem('categories'))

  useEffect(() => {
    const errorSet = new Set(Object.keys(errors));
    const touchSet = new Set(Object.keys(touched));

    function difference(setA, setB) {
      let _difference = new Set(setA)
      for (let elem of setB) {
        _difference.delete(elem)
      }
      return _difference
    }

    setProgress(difference(touchSet, errorSet).size)

  });
  console.log(categories);

  return (
    <>

      {/* <div className="header bg-gradient-info pb-8 pt-5 pt-md-6"></div> */}
      {/* Page content */}
      <Container className=" mt--0 pb-5" fluid="xl">

        <div className="justify-content-center col">
          <Col className="col-sm-12  col-md-7 offset-md-2" fluid>
            <Card className="bg-secondary shadow border-0">

              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <h2>Register Customer</h2>
                  <Progress animated value={progress * 20} />
                </div>
                <FormikForm autoComplete="off">

                  {touched.firstName && errors.firstName && <small className="error-message">{errors.firstName}</small>}
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-circle-08" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Field placeholder="First Name*" type="text" name="firstName" className="form-control" />
                    </InputGroup>
                  </FormGroup>

                  {touched.lastName && errors.lastName && <small className="error-message">{errors.lastName}</small>}
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-circle-08" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Field placeholder="Last Name*" type="text" name="lastName" className="form-control" />
                    </InputGroup>
                  </FormGroup>

                  {/* {touched.customerReference && errors.customerReference && <small className="error-message">{errors.customerReference}</small>}
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-bulb-61" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Field placeholder="Customer Reference*" type="text" name="customerReference" className="form-control" />
                    </InputGroup>
                  </FormGroup>

                  {touched.category && errors.category && <small className="error-message">{errors.category}</small>}
                  {categories &&
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-collection" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Field as="select" name="category" className="form-control">
                        <option defaultValue hidden>Category*</option>
                        {
                          categories && [...categories].map((c) => {
                           return <option value={c.key}>{c.value}</option>
                          })
                        }
                      </Field>
                    </InputGroup>
                  </FormGroup>} */}

                  {touched.phone && errors.phone && <small className="error-message">{errors.phone}</small>}
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-mobile-button" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Field placeholder="Phone Number*" type="tel" name="phone" className="form-control" />
                    </InputGroup>
                  </FormGroup>

                  {touched.customer_phone1 && errors.customer_phone1 && (
                    <small className="error-message">
                      {errors.customer_phone1}
                    </small>
                  )}
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-mobile-button" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Field
                        placeholder="Second Phone Number"
                        type="tel"
                        name="customer_phone1"
                        className="form-control"
                      />
                    </InputGroup>
                  </FormGroup>

                  {touched.customer_phone2 && errors.customer_phone2 && (
                    <small className="error-message">
                      {errors.customer_phone2}
                    </small>
                  )}
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-mobile-button" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Field
                        placeholder="Third Phone Number"
                        type="tel"
                        name="customer_phone2"
                        className="form-control"
                      />
                    </InputGroup>
                  </FormGroup>

                  {touched.email && errors.email && <small className="error-message">{errors.email}</small>}
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Field placeholder="Email" type="email" name="email" className="form-control" />
                    </InputGroup>
                  </FormGroup>
                  <Row className="my-4">

                  </Row>
                  <div className="text-center">
                    <Button className="mt-4" type="submit" color="primary" disabled={hasChanged || hasErrors || isSubmitting}>
                      {isSubmitting ? <span><i className="fas fa-spinner fa-spin"></i>Please wait...</span> : 'Create Account'}
                    </Button>
                  </div>
                </FormikForm>
              </CardBody>
            </Card>
          </Col>
        </div>

      </Container>
    </>
  );
}


const RegisterACustomer = withFormik({
  mapPropsToValues({ email, history, customerName, phone, phone1, phone2, address, customerReference, category }) {
    return {
      email: '',
      customerName: '',
      phone: '',
      customer_phone1: '',
      customer_phone2: '',
      address: '',
      customerReference: '',
      category: '',
      history
    }
  },
  validationSchema: Yup.object().shape({
    email: Yup.string().email('Email must be valid'),
    lastName: Yup.string('Invalid name').required('Last name is required').min(3, 'Please provide your last name'),
    firstName: Yup.string('Invalid name').required('First name is required').min(3, 'Please provide your first name'),
    phone: Yup.string('Invalid Phone Number').required('Phone number is required'),
    customer_phone1: Yup.string().min(11, "Phone number digits incomplete"),
    customer_phone2: Yup.string().min(11, "Phone number digits incomplete"),
    // customerReference: Yup.string().required('Customer Reference is required'),
    // category: Yup.string().required('Service category is required')
  }),
  async handleSubmit(values, { resetForm, setErrors, setSubmitting, props }) {
    try {
      const token = sessionStorage.getItem('token')
      const baseUrl = sessionStorage.getItem('type') === 'other' ? '/admin' : '/utility'
      const passcode = CryptoJS.HmacSHA512(token, "123NDSx").toString();

      const data = {

        token,
        action: "register",
        customer_name: `${values.firstName} ${values.lastName}`,
        customer_phone: values.phone,
        customer_email: values.email,
        customer_phone1: values.customer_phone1,
        customer_phone2: values.customer_phone2,
        customer_address: '',
        customer_ref: props.validatedData.reference,
        passcode,
        category: props.validatedData.category,
        service: sessionStorage['service']

      }

      const response = await fetch('https://irecharge.com.ng/api/adept/register_customer_sp.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })

      const responseData = await response.json()


      if (responseData.status === '0') {

        sessionStorage.setItem("createdAccount", JSON.stringify(responseData));
        resetForm();
        setSubmitting(false);
        props.history.push(`${baseUrl}/account-result`);

      } else if (responseData.status === '-2') {
        return Swal.fire({
          icon: 'info',
          title: responseData.message,
          text: 'Please login again',
          timer: 2000,
          onClose: () => {
            props.history.push('/auth/login')
          }
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            props.history.push('/auth/login')
          }
        })
      }
      else {
        return Swal.fire({
          icon: 'info',
          text: responseData.message
        })
      }
    } catch (err) {
      return Swal.fire({
        icon: 'error',
        title: 'Registration Failed',
        text: 'Unable to register customer'
      })

    }

  }
})(RegisterCustomer);

export default RegisterACustomer;
