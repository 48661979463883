import React from "react";
import CryptoJS from 'crypto-js';
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
// node.js library that concatenates classes (strings)
// javascipt plugin for creating charts
import Chart from "chart.js";
import classnames from "classnames";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
import swalReact from "@sweetalert/with-react";


// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  Button,
  Progress,
  Table,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,

} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  fetchData,
} from "variables/charts.jsx";

import Header from "components/Headers/Header.jsx";
import Loader from "./pages/Loader";
import { NavLink } from "react-router-dom";

class Index extends React.Component {
  state = {
    activeNav: 1,
    chartExample1Data: "data1",
    chartData: {},
    payments: [],
    customersCount: '',
    salesValue: '',
    settlementValue: '',
    commission: '',
    loading: false,
    customers: [],
    paymentsList: [],
    paymentsCount: '',
    role: sessionStorage.getItem('role'),
    admin: sessionStorage.getItem('role') === 'admin' || sessionStorage.getItem('role') === 'management',
    user: sessionStorage.getItem('type'),
    baseUrl: sessionStorage.getItem('type') === 'other' ? '/admin' : '/utility'
  };
  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };
  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }
  changeRoute = (name) => {
    this.props.history.push(name);
  };

  CustomerViewMore = customer => {
    swalReact({
      content: (
        <Col xl="14">
          <Card className="shadow align-innertext">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <div className="col">
                  <h3 className="mb-0">Customer details</h3>
                </div>
              </Row>
            </CardHeader>
            <Table className="align-items-center table-flush" responsive>
              <tbody>
                <tr>
                  <th scope="col">customer_ref</th>
                  <td>{customer.customer_ref}</td>
                </tr>
                <tr>
                  <th scope="col">Account Number</th>
                  <td>{customer.account_number}</td>
                </tr>
                <th scope="col">Account Name</th>
                <td>{customer.account_name}</td>
                <tr>
                  <th scope="col">Bank Name</th>
                  <td>{customer.bank}</td>
                </tr>
                <th scope="col">Customer Name</th>
                <td>{customer.customer_name}</td>
                <tr>
                  <th scope="col">Customer Email</th>
                  <td>{customer.customer_email}</td>
                </tr>
                <th scope="col">Phone Number</th>
                <td>{customer.customer_phone}</td>
                <tr>
                  <th scope="col">Address</th>
                  <td>{customer.customer_address ? customer.customer_address : "Address not found"}</td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </Col>
      )
    });
  };

  PaymentViewMore = payment => {
    swalReact({
      content: (
        <Col xl="14">
          <Card className="shadow align-innertext">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <div className="col">
                  <h3 className="mb-0">Payment details</h3>
                </div>
              </Row>
            </CardHeader>
            <Table className="align-items-center table-flush" responsive>
              <tbody>
                <tr>
                  <th scope="col">Payment_ref</th>
                  <td>{payment.payment_ref}</td>
                </tr>
                <tr>
                  <th scope="col">Account Number</th>
                  <td>{payment.account_number}</td>
                </tr>
                <th scope="col">Account Name</th>
                <td>{payment.account_name}</td>
                <tr>
                  <th scope="col">Bank Name</th>
                  <td>{payment.bank}</td>
                </tr>
                <th scope="col">Transaction Id</th>
                <td>{payment.transaction_id}</td>
                <tr>
                  <th scope="col">Amount</th>
                  <td>{payment.amount}</td>
                </tr>
                <th scope="col">Amount Dispense</th>
                <td>{payment.amount_dispense}</td>
                <tr>
                  <th scope="col">Service Charge</th>
                  <td>{payment.service_charge}</td>
                </tr>
                <tr>
                  <th scope="col">Source</th>
                  <td>{payment.source}</td>
                </tr>
                <tr>
                  <th scope="col">Utility</th>
                  <td>{payment.utility_ref}</td>
                </tr>
                <tr>
                  <th scope="col">Time Stamp</th>
                  <td>{payment.timestamp}</td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </Col>
      )
    });
  };
  
  async componentDidMount() {
    const { admin, user, role } = this.state;
    this.setState({ loading: true })
    let url;
    let action;

    if (user === 'service_provider' && admin || user === 'other' ) {
      url = 'https://irecharge.com.ng/api/adept/dashboard.php';
      action = 'dashboard';
    } else if (user === 'service_provider' && !admin && role == 'representative') {
      url = 'https://irecharge.com.ng/api/adept/rep_dashboard.php';
      action = 'rep_dashboard';
    } else {
      url = 'https://irecharge.com.ng/api/adept/agent_dashboard.php';
      action = 'agent_dashboard';
    }
    // if (user === 'service_provider' && !admin) {
    //    url = 'https://irecharge.com.ng/api/adept/agent_dashboard.php';
    //    action = 'agent_dashboard';
    // } else {
    //   url = 'https://irecharge.com.ng/api/adept/dashboard.php';
    //   action = 'dashboard';
    // }

    try {

      const token = sessionStorage.getItem('token');
      const passcode = CryptoJS.HmacSHA512(token, "123NDSx").toString();

      let payments;

      const res = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
          passcode,
          action
        })
      });
      const response = await res.json();

      if (response.status === '0' && admin) {
        payments = response.payments;
        let chartData = fetchData(payments);

        this.setState({
          customersCount: response.total_customers,
          salesValue: response.total_payments,
          commission: response.commission_earned,
          salesCount: response.total_payments_count,
          chartData,
          loading: false
        })
      } 
      else if (response.status === '0' && !admin && role == 'representative') {
        this.setState({
          customers: response.customers,
          paymentsList: response.payments,
          loading: false
        })
      } else if (response.status === '0' && !admin) {
        this.setState({
          customersCount: response.total_customers,
          salesValue: response.total_payment_value,
          settlementValue: response.total_settlement_value,
          commission: response.total_commission_value,
          salesCount: response.total_payments_count,
          loading: false
        })
      } else if (response.status === '-2') {
        return Swal.fire({
          icon: 'info',
          title: response.message,
          text: 'Please login again',
          timer: 2000,
          onClose: () => {
            this.props.history.push('/auth/login')
          }
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            this.props.history.push('/auth/login')
          }
        })
      }

    } catch (error) {
      this.setState({
        loading: false
      });
      Swal.fire({
        icon: 'error',
        title: 'Unable to load customers',
        text: 'Please check your internet and try again or contact support',
        position: 'center',
        onClose: () => {
          window.location.reload()
        }
      })

    }
  }

  render() {
    const { loading, user, admin, commission, role, customers, paymentsList,
      customersCount, salesValue, settlementValue, salesCount } = this.state;
    return (
      loading ? <Loader title="Dashboard" /> :
        <>
          { sessionStorage.getItem('role') !== 'representative' ?
            <Header
              data={
                user === 'service_provider' && !admin
                  ?
                  [
                    {
                      title: 'Customers',
                      value: customersCount,
                      icon: 'fas fa-users',
                      iconStyle: 'icon icon-shape bg-yellow text-white rounded-circle shadow',
                      barStyle: 'bg-yellow'
                    },
                    {
                      title: 'Sales Value',
                      value: `₦ ${parseFloat(salesValue).toLocaleString(
                        'en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                      )}`,
                      icon: 'fas fa-chart-bar',
                      iconStyle: 'icon icon-shape bg-green text-white rounded-circle shadow',
                      barStyle: 'bg-green'
                    },
                    {
                      title: 'Sales Count',
                      value: salesCount,
                      icon: 'fas fa-chart-bar',
                      iconStyle: 'icon icon-shape bg-green text-white rounded-circle shadow',
                      barStyle: 'bg-green'
                    },
                    {
                      title: 'Settlement',
                      value: `₦ ${parseFloat(settlementValue).toLocaleString(
                        'en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                      )}`,
                      icon: 'fas fa-university',
                      iconStyle: 'icon icon-shape bg-blue text-white rounded-circle shadow',
                      barStyle: 'bg-blue'
                    },
                    {
                      title: 'Commission',
                      value: `₦ ${parseFloat(commission).toLocaleString(
                        'en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                      )}`,
                      icon: 'fas fa-percent',
                      iconStyle: 'icon icon-shape bg-info text-white rounded-circle shadow',
                      barStyle: 'bg-info'
                    },
                  ]
                  :
                  (commission > 0 ?
                    [
                      {
                        title: 'Customers',
                        value: customersCount,
                        icon: 'fas fa-users',
                        iconStyle: 'icon icon-shape bg-yellow text-white rounded-circle shadow',
                        barStyle: 'bg-yellow'
                      },
                      {
                        title: 'Sales Value',
                        value: `₦ ${parseFloat(salesValue).toLocaleString(
                          'en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                        )}`,
                        icon: 'fas fa-chart-bar',
                        iconStyle: 'icon icon-shape bg-green text-white rounded-circle shadow',
                        barStyle: 'bg-green'
                      },
                      {
                        title: 'Sales Count',
                        value: salesCount,
                        icon: 'fas fa-chart-bar',
                        iconStyle: 'icon icon-shape bg-green text-white rounded-circle shadow',
                        barStyle: 'bg-green'
                      },
                      {
                        title: 'Commission',
                        value: `₦ ${parseFloat(commission).toLocaleString(
                          'en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                        )}`,
                        icon: 'fas fa-percent',
                        iconStyle: 'icon icon-shape bg-info text-white rounded-circle shadow',
                        barStyle: 'bg-info'
                      },
                    ]
                    :
                    [
                      {
                        title: 'Customers',
                        value: customersCount,
                        icon: 'fas fa-users',
                        iconStyle: 'icon icon-shape bg-yellow text-white rounded-circle shadow',
                        barStyle: 'bg-yellow'
                      },
                      {
                        title: 'Sales Value',
                        value: `₦ ${parseFloat(salesValue).toLocaleString(
                          'en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                        )}`,
                        icon: 'fas fa-chart-bar',
                        iconStyle: 'icon icon-shape bg-green text-white rounded-circle shadow',
                        barStyle: 'bg-green'
                      },
                      {
                        title: 'Sales Count',
                        value: salesCount,
                        icon: 'fas fa-chart-bar',
                        iconStyle: 'icon icon-shape bg-green text-white rounded-circle shadow',
                        barStyle: 'bg-green'
                      },

                    ])
              }
            /> : <Header data={[]} />
          }

          {/* Page content */}
          { (user === 'service_provider' && admin || user === 'other') &&
            <Container className="mt--7" fluid>

              <Row>
                <Col className="mb-5 mb-xl-0" xl="12">
                  <Card className="bg-gradient-default shadow">
                    <CardHeader className="bg-transparent">
                      <Row className="align-items-center">
                        <div className="col">
                          <h6 className="text-uppercase text-light ls-1 mb-1">
                            Overview
                      </h6>
                          <h2 className="text-white mb-0">Monthly sales value</h2>
                        </div>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      {/* Chart */}
                      <div className="chart">
                        <Line
                          data={this.state.chartData[this.state.chartExample1Data]}
                          options={this.state.chartData.options}
                          getDatasetAtEvent={e => console.log(e)}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          }

          { (user === 'service_provider' && !admin && role === 'representative') &&
            <Container className="mt--7" fluid>
              <Row className="mt-5">
                <Col className="mb-5 mb-xl-0" xl="6">
                  <Card className="shadow">
                    <CardHeader className="border-0">
                      <Row className="align-items-center">
                        <div className="col">
                          <h3 className="mb-0">Customer</h3>
                        </div>
                        <div className="col text-right">
                          <Button
                            color="primary"
                            onClick={() => this.changeRoute(`${this.state.baseUrl}/customers`)}
                            size="sm"
                          >
                            See all
                      </Button>
                        </div>
                      </Row>
                    </CardHeader>
                    <Table className="align-items-center table-flush" responsive>
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Account name</th>
                          <th scope="col">Account Number</th>
                          <th scope="col">Reference</th>
                        </tr>
                      </thead>
                      <tbody>
                        {customers.map(customer => {
                          return (
                            <tr key={customer.customer_id}>
                              <td>{customer.account_name}</td>
                              <td>{customer.account_number}</td>
                              <td>{customer.customer_ref}</td>
                              <td className="text-right">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={e => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow"
                                    right
                                  >
                                    <DropdownItem
                                      onClick={() => this.CustomerViewMore(customer)}
                                    >
                                      <Link to={`${this.state.baseUrl}/index`}>View More</Link>
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Card>
                </Col>
                <Col className="mb-5 mb-xl-0" xl="6">
                  <Card className="shadow">
                    <CardHeader className="border-0">
                      <Row className="align-items-center">
                        <div className="col">
                          <h3 className="mb-0">Payments</h3>
                        </div>
                        <div className="col text-right">
                          <Button
                            color="primary"
                            onClick={() => this.changeRoute(`${this.state.baseUrl}/payments`)}
                            size="sm"
                          >
                            See all
                      </Button>
                        </div>
                      </Row>
                    </CardHeader>
                    <Table className="align-items-center table-flush" responsive>
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Account name</th>
                          <th scope="col">Account Number</th>
                          <th scope="col">Payment Reference</th>

                        </tr>
                      </thead>
                      <tbody>
                        {paymentsList.map(payment => {
                          return (
                            <tr key={payment.payment_id}>
                              <td>{payment.account_name}</td>
                              <td>{payment.account_number}</td>
                              <td>{payment.payment_ref}</td>
                              <td className="text-right">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={e => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow"
                                    right
                                  >
                                    <DropdownItem
                                      onClick={() => this.PaymentViewMore(paymentsList)}
                                    >
                                      <Link to={`${this.state.baseUrl}/index`}>View More</Link>
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Card>
                </Col>

              </Row>
            </Container>
          }
        </>
    );
  }
}
export default Index;