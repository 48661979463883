import {
  GET_SETTLEMENTS,
  GET_DATE_SORT_SETTLEMENTS,
  UPDATE_CURRENT_PAGE,
  GET_SETTLEMENTS_EXPORT
} from './actionTypes';
import callApi from './../../utilitySevice/api';
import Swal from 'sweetalert2';
import { handleError } from '../../utilitySevice/apiError';

export const getSettlements = (data, cb) => async (dispatch) => {
  const admin =
    sessionStorage.getItem('role') === 'admin' ||
    sessionStorage.getItem('role') === 'management';
  const user = sessionStorage.getItem('type');

  const url =
    user === 'service_provider' && !admin
      ? 'get_agent_settlements.php'
      : 'get_settlements.php';
  try {
    const res = await callApi(`/${url}`, data, 'POST');
    if (res.status === "0") {
      const { items, current_page, next_page, total_pages } = res;
        dispatch({
          type: GET_SETTLEMENTS,
          numberOfPages: total_pages,
          items: items,
          current_page: current_page,
        })
    }else{
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: res.message
          
      });
      dispatch({
        type: GET_SETTLEMENTS,
        numberOfPages: 1,
        items: [],
        current_page:1,
      })
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'Unable to load settlements',
      text:
        'Please check your internet connection and try again or contact support',
    });
  } finally {
    cb();
  }
};

export const getSettlementsExport = (data, cb) => async (dispatch) => {
  const admin =
    sessionStorage.getItem('role') === 'admin' ||
    sessionStorage.getItem('role') === 'management';
  const user = sessionStorage.getItem('type');

  const url =
    user === 'service_provider' && !admin
      ? 'get_agent_settlements.php'
      : 'get_settlements.php';
  try {
    const res = await callApi(`/${url}`, data, 'POST');
    if (res.status === "0") {
      const { items } = res;
        dispatch({
          type: GET_SETTLEMENTS_EXPORT,
          payload: items,
        })
    }else{
      dispatch({
        type: GET_SETTLEMENTS_EXPORT,
        payload: []
      })
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: res.message
      });

    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'Unable to load settlements',
      text:
        'Please check your internet connection and try again or contact support',
    });
  } finally {
    cb();
  }
};


export const updateCurrentPage = (data, history, cb) => (dispatch) => {
  dispatch({
    type: UPDATE_CURRENT_PAGE,
    current_page: data
  })
  cb();
};

export const getDateSettlements = (data, cb) => async (dispatch) => {
  try {
    const res = await callApi('/get_settlements.php', data, 'POST');
    if (res) {
      const { items, newPage } = res;
      dispatch(
        dispatch({ type: 'GET_SETTLEMENTS', items: items, newPage: newPage })
      );
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'Unable to load payments',
      text:
        'Please check your internet connection and try again or contact support',
    });
  } finally {
    cb();
  }
};
