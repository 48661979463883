import Swal from 'sweetalert2';

// This method contains configuration for handling error states from API after a request fails.
export const handleApiError = (error) => {
  if (process.env.NODE_ENV !== 'production') {
    console.warn('[API Error]', error, error.message);
  }
  if (!error.message) return 'Something went wrong';

  // the error codes are just examples, we can inquire about the error codes that will be returned in various instances

  switch (error.status) {
    case -1:
      return error.message || 'Something went wrong';
    case -2:
      return error.message || 'Resource not found';
    case 2:
      return 'A duplicate already exists';
    default:
      return 'Bad Request';
  }
};

export const handleError = (res, history) => {
  switch (res.status) {
    case "-1":
      return Swal.fire({
        icon: "error",
        text: res.message,
        position: "center"
      });
    case "-2":
      return Swal.fire({
        icon: "error",
        title: res.message,
        text: "Please login again",
        timer: 2000,
        onClose: () => {
            history.push("/auth/login");
        }
      }).then(result => {
          if (result.dismiss === Swal.DismissReason.timer) {
              history.push("/auth/login");
          }
      });
    case "2":
      return Swal.fire({
        icon: "info",
        text: res.message
      });
    default:
      return Swal.fire({
        icon: "info",
        text: res.message
      });
  }
};

