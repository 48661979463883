import {
  SAVE_ACCOUNT,
  GET_BANKS,
  VALIDATE_ACCOUNT,
  GET_ACCOUNT,
} from './actionTypes';
import Swal from 'sweetalert2';
import callApi from './../../utilitySevice/api';

export const getBanks = (data, cb) => async (dispatch) => {
  try {
    const res = await callApi('/get_banks.php/', data, 'POST');
    if (res.status === "0") {
      const { banks } = res;

      dispatch(
        dispatch({
          type: 'GET_BANKS',
          banks: banks,
        })
      );
    }
  } catch (error) {
    console.log(error);
    Swal.fire({
      icon: 'error',
      title: error,
      text: error,
      position: 'center',
    });
  } finally {
    cb();
  }
};

export const validateAccount = (data, cb) => async (dispatch) => {
  const admin =
    sessionStorage.getItem('role') === 'admin' ||
    sessionStorage.getItem('role') === 'management';
  const user = sessionStorage.getItem('type');
  try {
    const res = await callApi('/validate_account.php', data, 'POST');
    const { customer_name } = res;
    if (res.status === "0") {
      dispatch(
        dispatch({
          type: 'VALIDATE_ACCOUNT',
          customer_name: customer_name,
        })
      );

      cb();
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'error',
      text: 'error',
      position: 'center',
    });
  } finally {
    cb();
  }
};

export const getAccount = (data, cb) => async (dispatch) => {
  const admin =
    sessionStorage.getItem('role') === 'admin' ||
    sessionStorage.getItem('role') === 'management';
  const user = sessionStorage.getItem('type');

  const getAccountUrl =
    user === 'service_provider' && !admin
      ? '/retrieve_agent_account.php'
      : '/retrieve_account.php';
  try {
    const res = await callApi(`${getAccountUrl}`, data, 'POST');

    if (res.status === "0") {
      dispatch(
        dispatch({
          type: GET_ACCOUNT,
          customer: res,
        })
      );
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'error',
      text: 'error',
    });
  } finally {
    cb();
  }
};

export const saveAcount = (data, cb) => async (dispatch) => {
  const admin =
    sessionStorage.getItem('role') === 'admin' ||
    sessionStorage.getItem('role') === 'management';
  const user = sessionStorage.getItem('type');

  const saveAccountUrl =
    user === 'service_provider' && !admin
      ? '/save_agent_account.php'
      : '/save_account.php';

  try {
    const res = await callApi(`${saveAccountUrl}`, data, 'POST');
    if (res.status === "0") {
      dispatch({
        type: 'SAVE_ACCOUNT',
        customer: data,
      })
      Swal.fire({
        icon: 'success',
        text: 'Your account has been updated!!',
        position: 'center',
      });
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      text: 'error',
      title: error,
      position: 'center',
    });
  } finally {
    cb();
  }
};
