import React, { useState } from 'react';
import CryptoJS from 'crypto-js';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Spinner,
} from 'reactstrap';
import './login.css';
import { sendLoginRequest } from '../../redux/actions/auth';

const Login = ({ history }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState(initialValues);
  const [submitting, setSubmitting] = useState(false);
  
  const initialValues = {
    password: '',
    username: '',
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleLogin = () => {
    const { username, password } = values;
    setSubmitting(true);
    dispatch(
      sendLoginRequest(
        {
          action: 'login',
          username: username,
          password: password,
          key: CryptoJS.HmacSHA512(username, '123NDSx').toString(),
        },
        history,
        () => {
          setSubmitting(false);
        }
      )
    );
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-single-02 text-grey" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    onChange={handleChange}
                    name="username"
                    placeholder="Username"
                    type="text"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    onChange={handleChange}
                    name="password"
                    placeholder="Password"
                    type="password"
                  />
                </InputGroup>
              </FormGroup>
              <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted">Remember me</span>
                </label>
              </div>
              <div>
                <Spinner color="primary" />
              </div>
              <div className="text-center">
                <Button
                  className="my-4"
                  color="primary"
                  type="button"
                  onClick={() => handleLogin()}
                  disabled={submitting}
                >
                  {submitting ? (
                    <span>
                      <i className="fas fa-spinner fa-spin"></i>Please wait...
                    </span>
                  ) : (
                    'Sign In'
                  )}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Login;
