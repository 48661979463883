import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col, Container } from 'reactstrap';
import classnames from 'classnames';
import ValidateCustomer from './ValidateCustomer';
import RegisterACustomer from './RegisterACustomer';
import { useHistory } from 'react-router-dom';

const Register = () => {
  const [activeTab, setActiveTab] = useState('1');
  const [validatedData, setValidatedData] = useState({})
  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const history = useHistory();

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-6"></div>
      <Container className=" mt--8 pb-5">
        <Nav className="justify-content-center col" tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
            onClick={() => { toggle('1'); }}
            >
              Validate
          </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
            // onClick={() => { toggle('2'); }}
            >
              Register
          </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
                <ValidateCustomer toggle={toggle} setValidatedData={setValidatedData} history={history} />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col sm="12">
                <RegisterACustomer toggle={toggle} validatedData={validatedData}  history={history} />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </Container>
    </>
  );
} 

export default Register;