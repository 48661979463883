import React from "react";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col, Progress } from "reactstrap";

class Header extends React.Component {
  render() {
    return (
      <>
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              {/* <Row>
              <div className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block">
              
              Customers List
            </div>
              </Row> */}
              <Row>
                {
                  this.props.data.map(d => {
                    return (
                      // <Col lg="10" xl="6" className={d.value === '' ? 'd-none' : null}>
                      <Col lg="10" xl="6"  key={d.title} className="mb-4">
                        <Card className="card-stats mb-4 mb-xl-0">
                          <CardBody>
                            <Row>
                              <div className="col">
                                <CardTitle
                                  tag="h5"
                                  className="text-uppercase text-muted mb-0"
                                >
                                  {d.title}
                                </CardTitle>
                                <span className="h2 font-weight-bold mb-0">
                                  {d.value}
                                </span>
                              </div>
                              <Col className="col-auto">
                                <div className={d.iconStyle}>
                                  <i className={d.icon} />
                                </div>
                              </Col>
                            </Row>
                            <p className="mt-3 mb-0 text-muted text-sm">
                              <Progress
                                max="100"
                                value="100"
                                barClassName={d.barStyle}
                              />
                            </p>
                          </CardBody>
                        </Card>
                    </Col>
                    )
                  })
                }
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default Header;
