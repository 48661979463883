import React, { useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';
import Swal from 'sweetalert2';
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Container,
  Progress,
} from 'reactstrap';
import { withFormik, Formik, Form as FormikForm, Field } from 'formik';
import * as Yup from 'yup';
import deepEqual from 'lodash.isequal';
import { useDispatch } from 'react-redux';
import { updateCustomer } from './../../../redux/actions/customer';
import { useHistory } from 'react-router-dom';

const getCustomerRef = (state) => {
  return state ? state.customer_ref : '';
};

const NewOwnerUpdate = ({
  initialValues,
  isSubmitting,
  location: { state },
}) => {
  const [progress, setProgress] = useState(0);
  const token = sessionStorage.getItem('token');
  const passcode = CryptoJS.HmacSHA512(token, '123NDSx').toString();
  const dispatch = useDispatch();
  const history = useHistory();

  const validationSchema = Yup.object().shape({
    customer_email: Yup.string().email('Email must be valid'),
    customer_name: Yup.string('Invalid customer name')
      .required('Customer name is required')
      .min(3, 'Please provide your fullname'),
    customer_phone: Yup.string()
      .min(11, 'Phone number digits incomplete')
      .required('Phone number is required'),
    customer_phone1: Yup.string().min(11, 'Phone number digits incomplete'),
    customer_phone2: Yup.string().min(11, 'Phone number digits incomplete'),
    customer_address: Yup.string().required('Address is required'),
    customer_ref: Yup.string().required('The customer reference is invalid'),
  });

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8"></div>

      <Container className=" mt--8 pb-5" fluid="xl">
        <div className="justify-content-center col">
          <Col className="col-sm-12  col-md-7 offset-md-2" fluid>
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <h2>Update Customer Details</h2>
                  <Progress animated value={progress * 16.7} />
                </div>
                <Formik
                  initialValues={{
                    customer_name: '',
                    customer_phone: '',

                    customer_email: '',
                    customer_address: '',
                    customer_ref: getCustomerRef(state),
                  }}
                  onSubmit={(data, { resetForm, setErrors, setSubmitting }) => {
                    setSubmitting(true);
                    const newVals = {
                      token,
                      action: 'register_additional_customer',
                      customer_name: data.customer_name,
                      customer_phone: data.customer_phone,

                      customer_email: data.customer_email,
                      customer_address: data.customer_address,
                      customer_ref: data.customer_ref,
                      passcode,
                      is_active: true,
                    };
                    dispatch(
                      updateCustomer(newVals, history, () =>
                        setSubmitting(false)
                      )
                    );
                  }}
                  // validationSchema={validationSchema}
                >
                  {({
                    values,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    touched,
                    errors,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      {touched.customer_name && errors.customer_name && (
                        <small className="error-message">
                          {errors.customer_name}
                        </small>
                      )}
                      <FormGroup>
                        <InputGroup className="input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-circle-08" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Field
                            placeholder="FullName*"
                            type="text"
                            name="customer_name"
                            defaultValue={values.customer_name}
                            className="form-control"
                          />
                        </InputGroup>
                      </FormGroup>

                      {touched.customer_ref && errors.customer_ref && (
                        <small className="error-message">
                          {errors.customer_ref}
                        </small>
                      )}
                      <FormGroup>
                        <InputGroup className="input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-bulb-61" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Field
                            placeholder="Invalid Customer Reference, Please Select a Customer"
                            type="text"
                            name="customer_ref"
                            className="form-control"
                            defaultValue={getCustomerRef(state)}
                            readOnly
                          />
                        </InputGroup>
                      </FormGroup>

                      {touched.customer_phone && errors.customer_phone && (
                        <small className="error-message">
                          {errors.customer_phone}
                        </small>
                      )}
                      <FormGroup>
                        <InputGroup className="input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-mobile-button" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Field
                            placeholder="Phone Number*"
                            type="tel"
                            name="customer_phone"
                            defaultValue={values.customer_phone}
                            className="form-control"
                          />
                        </InputGroup>
                      </FormGroup>

                      {touched.customer_address && errors.customer_address && (
                        <small className="error-message">
                          {errors.customer_address}
                        </small>
                      )}
                      <FormGroup>
                        <InputGroup className="input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-world-2" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Field
                            placeholder="Address*"
                            type="text"
                            name="customer_address"
                            defaultValue={values.customer_address}
                            className="form-control"
                          />
                        </InputGroup>
                      </FormGroup>

                      {touched.customer_email && errors.customer_email && (
                        <small className="error-message">
                          {errors.customer_email}
                        </small>
                      )}
                      <FormGroup>
                        <InputGroup className="input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Field
                            placeholder="Email*"
                            type="email"
                            name="customer_email"
                            defaultValue={values.customer_email}
                            className="form-control"
                          />
                        </InputGroup>
                      </FormGroup>
                      <div className="text-center">
                        <Button
                          className="mt-4"
                          type="submit"
                          color="primary"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? (
                            <span>
                              <i className="fas fa-spinner fa-spin"></i>Please
                              wait...
                            </span>
                          ) : (
                            'Update Details'
                          )}
                        </Button>
                      </div>
                    </form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </div>
      </Container>
    </>
  );
};

export default NewOwnerUpdate;
