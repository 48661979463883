import { END_AUTH_LOADING, START_AUTH_LOADING, LOGOUT } from './actionTypes';
import Swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import { LOGIN } from './actionTypes';
import { saveCookie, clearCookieValue } from '../../utilitySevice/Helpers';
import callApi from '../../utilitySevice/api';


export const sendLoginRequest = (data, history, cb) => async (dispatch) => {
  const { username, password, key } = data;

  saveCookie('passcode', key);
  dispatch({
    type: START_AUTH_LOADING,
  });

  try {
    const res = await callApi(
      '/login.php',
      {
        action: 'login',
        username: username,
        password: password,
        passcode: key,
      },
      'POST'
    );

    if (res.status === "0") {
  
      const { token, type, role, service, categories, company_logo } = res;

      dispatch(
        dispatch({
          type: LOGIN,
          user: res,
        })
      );
      sessionStorage.setItem('token', token);
      sessionStorage.setItem(
        'categories',
        JSON.stringify(categories)
      );
      sessionStorage.setItem('company_logo', company_logo);
      sessionStorage.setItem('service', service);
      sessionStorage.setItem('role', role);
      sessionStorage.setItem('isLoggedIn', true);
      sessionStorage.setItem('type', type);
      Swal.fire({
        icon: 'success',
        text: 'Login Successful',
        position: 'top-end',
        showClass: {
          popup: 'animated fadeInDown faster',
        },
        hideClass: {
          popup: 'animated fadeOutUp faster',
        },
      });

      if (type === 'service_provider') {
        history.push('/utility/index');
      } else {
        history.push('/admin/index');
      }
    }else{
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: res.message
      });
    }
  } catch (error) {
    cb();

    Swal.fire({
      icon: 'error',
      title: error.message,
      // text: 'Please check your internet connection and try again',
    });
  } finally {
    cb();
    dispatch({
      type: END_AUTH_LOADING,
    });
  }
};

export const logout = (history) => (dispatch) => {
  clearCookieValue('loginToken');
  history.push('/auth/login');
  dispatch({
    type: LOGOUT,
  });
};

export const resetPassword = (data, history, cb) => async (dispatch) => {
  const res = callApi('/change_password.php/', data, 'POST');
  if (res) {
    dispatch(dispatch({ type: LOGOUT }));
    history.push('/auth/login');
  }
};
