import { EDIT_CUSTOMER } from '../actions/actionTypes';

const initialState = {
  customerName: '',
  customerAddress: '',
  customerEmail: '',
  customerPhone: '',
  customerPhone1: '',
  customerPhone2: '',
  customerId: '',
  accountName: '',
  bank: '',
  accountNumber: '',
  customerRef: '',
  isLoading: false,
  customerLoading: false,
};

export const EditCustomerReducer = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_CUSTOMER:
      return {
        ...state,
        customerName: action.customer.customer_name,
        customerAddress: action.customer.customer_address,
        customerEmail: action.customer.customer_email,
        customerPhone: action.customer.customer_phone,
        customerPhone1: action.customer.customer_phone1,
        customerPhone2: action.customer.customer_phone2,
        customerId: action.customer.customer_id,
        accountName: action.customer.account_name,
        bank: action.customer_bank,
        accountNumber: action.customer.account_number,
        customerRef: action.customer.customer_ref,
        isLoading: false,
        customerLoading: false,
      };
    default:
      return state;
  }
};
