
import React, { useState, useEffect } from "react";
import CryptoJS from "crypto-js";
import Swal from 'sweetalert2';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
  Progress,
} from "reactstrap";
import { withFormik, Form as FormikForm, Field } from 'formik';
import * as Yup from 'yup'
import deepEqual from 'lodash.isequal';


const RegisterCustomer = ({
  initialValues,
  values,
  errors,
  touched,
  dirty,
  isSubmitting,
  history

}) => {

  const [progress, setProgress] = useState(0);
  const hasChanged = deepEqual(values, initialValues);
  const hasErrors = Object.keys(errors).length > 0;
  const categories = JSON.parse(sessionStorage.getItem('categories'))


  useEffect(() => {
    const errorSet = new Set(Object.keys(errors));
    const touchSet = new Set(Object.keys(touched));

    function difference(setA, setB) {
      let _difference = new Set(setA)
      for (let elem of setB) {
        _difference.delete(elem)
      }
      return _difference
    }

    setProgress(difference(touchSet, errorSet).size)

  });

  return (
    <>

      {/* <div className="header bg-gradient-info pb-8 pt-5 pt-md-6"></div> */}
      {/* Page content */}
      <Container className=" mt--0 pb-5" fluid="xl">

        <div className="justify-content-center col">
          <Col className="col-sm-12  col-md-7 offset-md-2" fluid>
            <Card className="bg-secondary shadow border-0">

              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <h4>Please, validate the customer before registeration.</h4>
                  <Progress animated value={progress * 20} />
                </div>
                <FormikForm autoComplete="off">   
                  {touched.customerReference && errors.customerReference && <small className="error-message">{errors.customerReference}</small>}
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-bulb-61" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Field placeholder="Utility Reference Number*" type="text" name="customerReference" className="form-control" />
                    </InputGroup>
                  </FormGroup>

                  {touched.category && errors.category && <small className="error-message">{errors.category}</small>}
                  {categories &&
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-collection" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Field as="select" name="category" className="form-control">
                        <option defaultValue hidden>Category*</option>
                        {
                          categories && [...categories].map((c) => {
                           return <option value={c.key}>{c.value}</option>
                          })
                        }
                      </Field>
                    </InputGroup>
                  </FormGroup>}

                  
                  <Row className="my-4">

                  </Row>
                  <div className="text-center">
                    <Button className="mt-4" type="submit" color="primary" disabled={hasChanged || hasErrors || isSubmitting}>
                      {isSubmitting ? <span><i className="fas fa-spinner fa-spin"></i>Please wait...</span> : 'Validate customer'}
                    </Button>
                  </div>
                </FormikForm>
              </CardBody>
            </Card>
          </Col>
        </div>

      </Container>
    </>
  );
}


const ValidateCustomer = withFormik({
  mapPropsToValues({ email, history, customerName, phone, phone1, phone2, address, customerReference, category }) {
    return {
      customerReference: '',
      category: '',
      history
    }
  },
  validationSchema: Yup.object().shape({
    customerReference: Yup.string().required('Utility Reference number is required'),
    category: Yup.string().required('Service category is required')
  }),
  async handleSubmit(values, { resetForm, setErrors, setSubmitting, props }) {


    try {
      console.log(values, 'values');

      const token = sessionStorage.getItem('token')

      const passcode = CryptoJS.HmacSHA512(token, "123NDSx").toString();
      const dataToValidate = {
        token,
        action: "validate",
        reference: values.customerReference,
        passcode,
        category: values.category,
        service: sessionStorage['service']
      }

           
      const response = await fetch('https://irecharge.com.ng/api/adept/validate.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToValidate),
      })

      const responseData = await response.json()


      if (responseData.status === '0') {
        props.setValidatedData({ category: values.category, reference: values.customerReference })
        resetForm();
        setSubmitting(false);
        return Swal.fire({
            icon: 'success',
            title: 'Validation successful!',
            html: `<strong>Utility reference number:</strong> ${responseData.reference}<br /> 
            <strong>Service:</strong> ${responseData.service} ${responseData.category}<br />
            <strong>Name:</strong> ${responseData.customer_name}<br />
            <strong>Address:</strong> ${responseData.customer_address}<br />`,
            onClose: () => {
              props.toggle('2')
            }
          }).then((result) => {
            if (result.dismiss) {
              props.toggle('2')
            }
          })

      } else if (responseData.status === '-2') {
        return Swal.fire({
          icon: 'info',
          title: responseData.message,
          text: 'Please login again',
          timer: 2000,
          onClose: () => {
            props.history.push('/auth/login')
          }
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            props.history.push('/auth/login')
          }
        })
      }
      else {
        return Swal.fire({
          icon: 'info',
          text: responseData.message
        })
      }
    } catch (err) {
      return Swal.fire({
        icon: 'error',
        title: 'Registration Failed',
        text: 'Unable to register customer'
      })

    }

  }
})(RegisterCustomer);

export default ValidateCustomer;
