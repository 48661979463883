import {
    USER_LIST,UPDATE_CURRENT_PAGE, SAVE_USER, REGISTER_USER,CLEAR_USER
  } from '../actions/actionTypes';
  const initialState = {
    isLoading: false,
    searchTerm: '',
    numberOfPages: 1,
    currentPage: 1,
    message: 'No users found',
    searchedDetailActive: false,
    searchActive: false,
    baseUrl: sessionStorage.getItem('type') === 'other' ? '/admin' : '/utility',
    users: [],
    user_name: '',
     user_email: '',
     user_phone: '',
     user_role:'',
     user_id:''
  };
  export const UserReducer = (state = initialState, action) => {
    switch (action.type) {
      case USER_LIST:
        return {
          ...state,
          searchActive: false,
          users: action.items,
          currentPage: action.current_page,
          numberOfPages: action.total_pages,
          isLoading: false,
        };
        case SAVE_USER:
            return {
              ...state,
              user_name: action.user.user_name,
              user_email: action.user.user_email,
              user_phone: action.user.user_phone,
              user_role: action.user.user_role,
              user_id: action.user.user_id
            };
            case CLEAR_USER:
              return {
                ...state,
                user_name: "",
                user_email: "",
                user_phone: "",
                user_id: ""
              };
        case UPDATE_CURRENT_PAGE:
            return {
              ...state,
              currentPage: action.current_page
            }
            case REGISTER_USER:
                return {
                  ...state,
                  users: action.user,
                  isLoading: false,
                };
      default:
        return state;
    }
  };