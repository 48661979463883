import React from 'react';
import CryptoJS from 'crypto-js';
// reactstrap components
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Col,
    Container,
} from 'reactstrap';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { AgentCreate } from './../../../redux/actions/agent';
import { useHistory } from 'react-router-dom';

const CreateAgent = () => {
    const token = sessionStorage.getItem('token');
    const passcode = CryptoJS.HmacSHA512(token, '123NDSx').toString();
    const dispatch = useDispatch();
    const history = useHistory();

    const validationSchema = Yup.object().shape({
        agent_email: Yup.string()
            .email("Email must be valid"),
        agent_name: Yup.string("Invalid Agent name")
            .required("Agent name is required")
            .min(3, "Please provide your fullname"),
        agent_phone: Yup.string()
            .min(11, "Phone number digits incomplete")
            .required("Phone number is required"),
    });


    return (
        <>
            <div className="header bg-gradient-info pb-8 pt-5 pt-md-8"></div>
            <Container className=" mt--8 pb-5" fluid="xl">
                <div className="justify-content-center col">
                    <Col className="col-sm-12  col-md-7 offset-md-2" fluid>
                        <Card className="bg-secondary shadow border-0">
                            <CardBody className="px-lg-5 py-lg-5">
                                <div className="text-center text-muted mb-4">
                                    <h2>Create Agent</h2>
                                </div>
                                <Formik
                                    initialValues={{
                                        agent_email: '',
                                        agent_name: '',
                                        agent_phone: ''
                                    }}
                                    onSubmit={(data, {setSubmitting }) => {
                                        setSubmitting(true);
                                        const newVals = {
                                            token,
                                            action: 'register_agent',
                                            agent_email: data.agent_email,
                                            agent_name: data.agent_name,
                                            agent_phone: data.agent_phone,
                                            passcode
                                        };
                                        dispatch(
                                            AgentCreate(newVals, history, () =>
                                                setSubmitting(false)
                                            )
                                        );
                                    }}
                                >
                                    {({ isSubmitting, handleSubmit, touched,errors}) => (
                                        <form onSubmit={handleSubmit}>
                                            {touched.agent_name && errors.agent_name && (
                                                <small className="error-message">
                                                    {errors.agent_name}
                                                </small>
                                            )}
                                            <FormGroup>
                                                <InputGroup className="input-group-alternative mb-3">
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="ni ni-circle-08" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Field
                                                        placeholder="Agent Name*"
                                                        type="text"
                                                        name="agent_name"
                                                        className="form-control"
                                                    />
                                                </InputGroup>
                                            </FormGroup>

                                            {touched.agent_phone && errors.agent_phone && (
                                                <small className="error-message">
                                                    {errors.agent_phone}
                                                </small>
                                            )}
                                            <FormGroup>
                                                <InputGroup className="input-group-alternative mb-3">
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="ni ni-mobile-button" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Field
                                                        placeholder="Phone Number*"
                                                        type="tel"
                                                        name="agent_phone"
                                                        className="form-control"
                                                    />
                                                </InputGroup>
                                            </FormGroup>

                                            {touched.agent_email && errors.agent_email && (
                                                <small className="error-message">
                                                    {errors.agent_email}
                                                </small>
                                            )}
                                            <FormGroup>
                                                <InputGroup className="input-group-alternative mb-3">
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="ni ni-email-83" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Field
                                                        placeholder="Email"
                                                        type="email"
                                                        name="agent_email"
                                                        className="form-control"
                                                    />
                                                </InputGroup>
                                            </FormGroup>
                                            <div className="text-center">
                                                <Button
                                                    className="mt-4"
                                                    type="submit"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                >
                                                    {isSubmitting ? (
                                                        <span>
                                                            <i className="fas fa-spinner fa-spin"></i>Please
                                                          wait...
                                                        </span>
                                                    ) : (
                                                        'Create Agent'
                                                    )}
                                                </Button>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </CardBody>
                        </Card>
                    </Col>
                </div>
            </Container>
        </>
    );
};

export default CreateAgent;
