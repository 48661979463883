import React from 'react';
import CryptoJS from 'crypto-js';
// reactstrap components
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Col,
    Container,
} from 'reactstrap';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { userCreate } from './../../../redux/actions/user';
import { useHistory } from 'react-router-dom';

const CreateUser = () => {
    const token = sessionStorage.getItem('token');
    const passcode = CryptoJS.HmacSHA512(token, '123NDSx').toString();
    const dispatch = useDispatch();
    const history = useHistory();
    // const [submitting, setSubmitting] =useEffect(false)

    const validationSchema = Yup.object().shape({
        user_email: Yup.string()
            .email("Email must be valid"),
        user_name: Yup.string("Invalid user name")
            .required("User name is required")
            .min(3, "Please provide your fullname"),
        user_phone: Yup.string()
            .min(11, "Phone number digits incomplete")
            .required("Phone number is required"),
    });

    return (
        <>
            <div className="header bg-gradient-info pb-8 pt-5 pt-md-8"></div>
            <Container className=" mt--8 pb-5" fluid="xl">
                <div className="justify-content-center col">
                    <Col className="col-sm-12  col-md-7 offset-md-2" fluid>
                        <Card className="bg-secondary shadow border-0">
                            <CardBody className="px-lg-5 py-lg-5">
                                <div className="text-center text-muted mb-4">
                                    <h2>Create User</h2>
                                </div>
                                <Formik
                                    initialValues={{
                                        user_email: '',
                                        user_name: '',
                                        user_phone: '',
                                        user_role: ''
                                    }}
                                    onSubmit={(data, { setSubmitting }) => {
                                        setSubmitting(true);
                                        const newVals = {
                                            token,
                                            action: 'register_user',
                                            user_email: data.user_email,
                                            user_name: data.user_name,
                                            user_phone: data.user_phone,
                                            user_role: data.user_role,
                                            passcode
                                        };
                                        dispatch(
                                            userCreate(newVals, history, () =>setSubmitting(false)
                                            )
                                        );
                                    }}
                                >
                                    {({ isSubmitting, handleSubmit, touched, errors }) => (
                                        <form onSubmit={handleSubmit}>
                                            {touched.user_role && errors.user_role && <small className="error-message">{errors.user_role}</small>}
                                            <FormGroup>
                                                <InputGroup className="input-group-alternative mb-3">
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="ni ni-collection" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Field as="select" name="user_role" className="form-control">
                                                        <option defaultValue hidden>Select User Role*</option>
                                                        <option value="admin">Admin</option>
                                                        <option value="representative">Representative</option>
                                                    </Field>
                                                </InputGroup>
                                            </FormGroup>
                                            {touched.user_name && errors.user_name && (
                                                <small className="error-message">
                                                    {errors.user_name}
                                                </small>
                                            )}
                                            <FormGroup>
                                                <InputGroup className="input-group-alternative mb-3">
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="ni ni-circle-08" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Field
                                                        placeholder="User Name*"
                                                        type="text"
                                                        name="user_name"
                                                        className="form-control"
                                                    />
                                                </InputGroup>
                                            </FormGroup>

                                            {touched.user_phone && errors.user_phone && (
                                                <small className="error-message">
                                                    {errors.user_phone}
                                                </small>
                                            )}
                                            <FormGroup>
                                                <InputGroup className="input-group-alternative mb-3">
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="ni ni-mobile-button" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Field
                                                        placeholder="Phone Number*"
                                                        type="tel"
                                                        name="user_phone"
                                                        className="form-control"
                                                    />
                                                </InputGroup>
                                            </FormGroup>

                                            {touched.user_email && errors.user_email && (
                                                <small className="error-message">
                                                    {errors.user_email}
                                                </small>
                                            )}
                                            <FormGroup>
                                                <InputGroup className="input-group-alternative mb-3">
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="ni ni-email-83" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Field
                                                        placeholder="Email"
                                                        type="email"
                                                        name="user_email"
                                                        className="form-control"
                                                    />
                                                </InputGroup>
                                            </FormGroup>
                                            <div className="text-center">
                                                <Button
                                                    className="mt-4"
                                                    type="submit"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                >
                                                    {isSubmitting ? (
                                                        <span>
                                                            <i className="fas fa-spinner fa-spin"></i>Please
                                                          wait...
                                                        </span>
                                                    ) : (
                                                        'Create User'
                                                    )}
                                                </Button>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </CardBody>
                        </Card>
                    </Col>
                </div>
            </Container>
        </>
    );
};

export default CreateUser;
