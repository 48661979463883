import React from 'react';
import { Redirect, Route } from 'react-router-dom';


export default function UtilityWrapper({ routes }) {
  const serviceProvider = sessionStorage.getItem('type') === 'service_provider';
  const admin = sessionStorage.getItem('role') === 'admin' || sessionStorage.getItem('role') === 'management';

  const listRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/utility" && serviceProvider && admin) {
        return (
          <Route 
            exact
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } 
      else if (prop.layout === "/utility" && (prop.type === 'agent' || prop.type === 'representative') && serviceProvider && !admin) {
        return (
          <Route 
            exact
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } 
      // else {
      //   return <Redirect to="/notfound" />;
      // }
    });
  }

  return (
    <>
    {listRoutes(routes)}
   </>
  )
}