import { createStore, applyMiddleware } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import storage from 'redux-persist/lib/storage';
import combineReducers from './reducers/combineReducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};

// localStorage.setItem('persistedStore', JSON.stringify(storage));
// const retrievedStoreStr = localStorage.getItem('persistedStore'); // this is a string
// console.log(retrievedStoreStr);
// const retrievedStore = JSON.parse(retrievedStoreStr); // this will be a JSON object
// const reducer1 = retrievedStore.reducer1;

const persistedReducer = persistReducer(persistConfig, combineReducers);
let store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export const persistor = persistStore(store);
export default store;
