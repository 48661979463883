import {
  CUSTOMERS_LIST,
  CUSTOMER_PAYMENT_HISTORY,
  NEXT_PAGE,
  SAVE_CUSTOMER,
  CLEAR_CUSTOMER,
  CUSTOMER_PAYMENT_HISTORY_EXPORT
} from './actionTypes';
import Swal from 'sweetalert2';
import callApi from './../../utilitySevice/api';
import { history } from './../../App';
import { handleError } from '../../utilitySevice/apiError';

export const CustomerList = (data, history, cb) => async (dispatch) => {
  try {
    const res = await callApi('/get_customers.php', data, 'POST');

    if (res.status === "0") {
      const { token, items, current_page, total_pages } = res;
      dispatch(
        dispatch({
          type: CUSTOMERS_LIST,
          customer: res,
          items,
          current_page,
          total_pages,
        })
      );
    } else {
      handleError(res, history)
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'Unable to load customers',
      text: 'Please check your internet and try again or contact support',
      position: 'center',
    });
  } finally {
    cb();
  }
};

export const nextPage = (data) => async (dispatch) => {
  dispatch({ type: NEXT_PAGE, page: data });
};

export const getSearchTerm = (data, history, cb) => async (dispatch) => {
  try {
    const res = await callApi('/get_customers.php', data, 'POST');

    if (res.status === "0") {
      const { total_pages, items, current_page } = res;
      dispatch(dispatch({ type: CUSTOMERS_LIST, customer: res, items, current_page, total_pages }));
    } else {
      handleError(res, history)
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'error',
      text: 'error',
      position: 'center',
    });
  } finally {
    cb();
  }
};

export const updateCustomer = (data, history, cb) => async (dispatch) => {
  try {
    const type = sessionStorage.getItem('type');
    const res = await callApi(
      '/register_additional_customer.php',
      data,
      'POST'
    );

    if (res.status == "0") {
      sessionStorage.setItem('updatedCustomer', JSON.stringify(res));
      dispatch(dispatch({ type: SAVE_CUSTOMER, customer: res}));
      history.push('/utility/update-result');

      Swal.fire({
        icon: 'success',
        text: 'Customer updated successfully',
        showClass: {
          popup: 'animated fadeInDown faster'
        },
        hideClass: {
          popup: 'animated fadeOutUp faster'
        }
      });
    } else {
      handleError(res, history)
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'Update Failed',
      text: 'Unable to update customer details',
    });
  } finally {
    cb();
  }
};

export const customerPayment = (data, history, cb) => async (dispatch) => {
  try {
    const res = await callApi('/get_payments.php', data, 'POST');

    if (res.status === "0") {
      const {
        items,
        commission_earned,
        total_pages,
        current_page,
        response,
      } = res;
      dispatch(
        dispatch({
          type: CUSTOMER_PAYMENT_HISTORY,
          items: items,
          current_page: current_page,
          commission_earned: commission_earned,
          total_pages: total_pages,
          response,
        })
      );
    } else {
      dispatch({
        type: CUSTOMER_PAYMENT_HISTORY,
        items: [],
        current_page: 1,
        commission_earned: 0,
        total_pages: 1,
      })
      handleError(res, history)
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'error',
      text:
        'Please check your internet connection and try again or contact support',
    });
  } finally {
    cb();
  }
};

export const customerPaymentExport = (data, history, cb) => async (dispatch) => {
  try {
    const res = await callApi('/get_payments.php', data, 'POST');

    if (res.status === "0") {
      const {
        items,
      } = res;
      dispatch(
        dispatch({
          type: CUSTOMER_PAYMENT_HISTORY_EXPORT,
          payload: items
        })
      );
    } else {
      dispatch({
        type: CUSTOMER_PAYMENT_HISTORY_EXPORT,
        payload:[]
      })
      handleError(res, history)
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'error',
      text:
        'Please check your internet connection and try again or contact support',
    });
  } finally {
    cb();
  }
};

export const getCustomer= (data, history, cb) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const res = await callApi('/get_customer.php', data, 'POST');
    if (res) {
        dispatch({
          type: SAVE_CUSTOMER,
          customer: res,
        })
    }
    cb();
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'Update Failed',
      text: 'Unable to update agent details',
    });
  }
};

export const clearUpdateField = () => {
  return {
    type: CLEAR_CUSTOMER
  };
};