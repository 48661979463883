import {
  SAVE_ACCOUNT,
  GET_ACCOUNT,
  VALIDATE_ACCOUNT,
  GET_BANKS
} from '../actions/actionTypes';

const initialState = {
  Banks: [],
  bankCode: '',
  accountNumber: '',
  isLoading: false,
  currentDetails: {},
  customerName: '',
  bankName: '',
  accountNumber: '',
  bank:'',
  // customerName: '',
  code:''
};

export const SettlementReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BANKS:
      return {
        ...state,
        Banks: action.banks,
      };
    case GET_ACCOUNT:
      return {
        ...state,
        accountNumber: action.customer.receiver,
        bank: action.customer.bank,
        customerName: action.customer.customer_name,
        code: action.customer.code,
      };
    case VALIDATE_ACCOUNT:
      return {
        ...state,
        customerName: action.customer_name,
        isLoading: false,
      };
    case SAVE_ACCOUNT:
      return {
        ...state,
        accountNumber: action.customer.receiver,
        bank: action.customer.bank,
        customerName: action.customer.customer_name,
        code: action.customer.code,
      };
    default:
      return state;
  }
};
