import {
  LOGIN,
  LOGOUT,
  START_AUTH_LOADING,
  END_AUTH_LOADING,
} from '../actions/actionTypes';

const initialState = {
  isLoading: false,
  user: null,
  isLoggedIn: false,
  clearStorage: '',
};

export const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_AUTH_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case END_AUTH_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case LOGIN:
      return {
        ...state,
        user: action.user,
        isLoggedIn: true,
        userRole: action.user.userRole,
      };
    case LOGOUT:
      return {
        ...state,
        clear: sessionStorage.clear(),
        isLoading: false,
        user: null,
        isLoggedIn: false,
      };

    default:
      return state;
  }
};
