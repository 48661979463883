import React from 'react';
import CryptoJS from 'crypto-js';
import Swal from 'sweetalert2';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
} from 'reactstrap';
import { Form, Field, Formik } from 'formik';
import * as Yup from 'yup';
import deepEqual from 'lodash.isequal';
import { resetPassword } from './../../../redux/actions/auth';
import { useDispatch } from 'react-redux';

import { useHistory } from 'react-router-dom';

const ResetPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const token = sessionStorage.getItem('token');

  const passcode = CryptoJS.HmacSHA512(token, '123NDSx').toString();

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required('Current password is required'),
    newPassword: Yup.string()
      .required('New Password is required')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Password Must Contain at least 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
      )
      .notOneOf(
        [Yup.ref('currentPassword'), null],
        'Old and new password must not be the same'
      ),
    retypeNewPassword: Yup.string()
      .required('Please retype your new Password')
      .oneOf([Yup.ref('newPassword'), null], 'New passwords must match'),
  });
  return (
    <div>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8"></div>

      <Container className=" mt--8 pb-5" fluid="xl">
        <div className="justify-content-center col">
          <Col className="col-sm-12  col-md-7 offset-md-2" fluid>
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <h2>Reset Password</h2>
                </div>
                <Formik
                  initialValues={{
                    currentPassword: '',
                    newPassword: '',
                    retypeNewPassword: '',
                  }}
                  onSubmit={(data, { setSubmitting, resetForm }) => {
                    const newVals = {
                      current_password: data.currentPassword,
                      new_password: data.newPassword,
                      action: 'change_password',
                      token: token,
                      passcode: passcode,
                    };
                    setSubmitting(true);
                    dispatch(
                      resetPassword(newVals, history, () => {
                        setSubmitting(false);
                        resetForm();
                      })
                    );
                  }}
                  // validationSchema={validationSchema}
                >
                  {({
                    values,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <FormGroup>
                        <InputGroup className="input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-lock-circle-open" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Field
                            placeholder="Current Password*"
                            type="password"
                            name="currentPassword"
                            value={values.currentPassword}
                            className="form-control"
                            autoComplete="off"
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup>
                        <InputGroup className="input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i class="fas fa-lock"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Field
                            placeholder="New Password*"
                            type="password"
                            name="newPassword"
                            value={values.newPassword}
                            className="form-control"
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup>
                        <InputGroup className="input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i class="fas fa-lock"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Field
                            placeholder="Re-type New Password*"
                            type="password"
                            name="retypeNewPassword"
                            value={values.retypeNewPassword}
                            className="form-control"
                          />
                        </InputGroup>
                      </FormGroup>
                      <Row className="my-4"></Row>
                      <div className="text-center">
                        <Button
                          className="mt-4"
                          type="submit"
                          color="primary"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? (
                            <span>
                              <i className="fas fa-spinner fa-spin"></i>Please
                              wait...
                            </span>
                          ) : (
                            'Reset Password'
                          )}
                        </Button>
                      </div>
                    </form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </div>
      </Container>
    </div>
  );
};

export default ResetPassword;
