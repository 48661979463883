import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
// reactstrap components
import { Container } from 'reactstrap';
// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.jsx';
import AdminFooter from 'components/Footers/AdminFooter.jsx';
import Sidebar from 'components/Sidebar/Sidebar.jsx';

import routes from 'routes.js';
import UtilityWrapper from '../components/Wrappers/UtilityWrapper';


class Utility extends React.Component {
  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }
  getRoutes = (routes) => {
    if (
      sessionStorage.getItem('type') === 'service_provider' && sessionStorage.getItem('isLoggedIn')
     ) {
      return <UtilityWrapper routes={routes} />;
    }
    // else if (sessionStorage.getItem('type') === 'service_provider' && sessionStorage.getItem('role') === 'agent' && sessionStorage.getItem('isLoggedIn')) {

    // }
    // else {
    //   return <Redirect to="/auth/login" />;
    // }
    // });
  };

  render() {
    return (
      <>
        <Sidebar
          {...this.props}
          routes={routes}
          logo={{
            innerLink: '/admin/index',
            imgSrc: require('assets/img/brand/logo1.png'),
            imgAlt: 'Adept Logo',
          }}
        />
        <div className="main-content" ref="mainContent">
          <AdminNavbar {...this.props} />
          <Switch>
            {this.getRoutes(routes)}

            <Route path="*">
              <Redirect to="/notfound" />
            </Route>
          </Switch>
          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    );
  }
}

export default Utility;
