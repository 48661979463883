
// import { CopyToClipboard } from "react-copy-to-clipboard";
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Badge,
  Table,
  Button
} from "reactstrap";
// core components

class AccountResult extends React.Component {
  state = {
    baseUrl: sessionStorage.getItem('type') === 'other' ? '/admin' : '/utility'
  }

  componentWillMount(){
    const userAccount =  JSON.parse(sessionStorage.getItem("createdAccount"));
    this.setState({userAccount});
  }
  render() {
    const user = this.state.userAccount;
    const {history} = this.props;
    return (
      <>
<div className="header bg-gradient-info pb-8 pt-5 pt-md-8"></div>
<Container className=" mt--9 section-to-print" fluid="xl">
<img
                alt="Adept"
                className="receipt-logo"
                src={require("assets/img/brand/logo1.png")}
              />
<div className="center-child">
        <Col xl="8">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Account Successfully Created</h3>
                    </div>
                    <div className="col text-right">
                    <Badge color="success">Success</Badge>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Customer Reference</th>
                      <th scope="col">{user && user.customer_ref}</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Account Name</th>
                      <td>{user && user.account_name}</td>
                      
                    </tr>
                    <tr>
                      <th scope="row">Bank</th>
                      <td>{user && user.bank}</td>
                      
                    </tr>
                    <tr>
                      <th scope="row">Account Number</th>
                      <td>{user && user.account_number}</td>
                      
                    </tr>
                    <tr>
                      <th scope="row">Customer Name</th>
                     <td>{user && user.customer_name}</td>
                      
                    </tr>
                    <tr>
                      <th scope="row">Primary Phone Number</th>
                      <td>{user && user.customer_phone}</td>
                      
                    </tr>
                    {this.state.baseUrl === 'other' && <tr>
                      <th scope="row">Other Phone Numbers</th>
                      <td>{user && user.customer_phone1} {user && user.customer_phone2}</td>
                    </tr>}
                    <tr>
                      <th scope="row">Email</th>
                      <td>{user && user.customer_email}</td>

                      
                    </tr>
                    <tr>
                      <th scope="row">Address</th>
                      <td>{user && user.customer_address}</td>
                      
                    </tr>
                    <tr className="receipt-footer">
                      <th>
                      <div className="col text-left">
                            <Button color="success" onClick={() => history.push(`${this.state.baseUrl}/register-customer`)}>Register new customer</Button>
                          </div>
                      </th>
                      <td>
                    <div className="col text-right">
                    <Button color="success"  onClick={() => { window.print()}}>Print</Button>
                    </div>
                    </td>
                    </tr>
                  </tbody>
                </Table>
              </Card>
            </Col>
            </div>
            <div className="receipt-sponsor">Powered By: www.irechargepay.com</div>
            </Container>
      </>
    );
  }
}

export default AccountResult;
