import Index from 'views/Index.jsx';
// import Profile from "views/pages/Profile.jsx";
// import PaymentHistory from './redundant/PaymentHistory';
import RegisterCustomer from 'views/pages/RegisterCustomer.jsx';
import AccountResult from 'views/pages/AccountResult.jsx';
// import CustomerProfile from './views/pages/CustomerProfile';
import CustomerProfile from './views/pages/customer/CustomerProfile';
// import Customers from './redundant/Customers';
import Customers from './views/pages/customer/customers';
// import AdminResetPassword from 'views/pages/ResetPassword.jsx';
import AdminResetPassword from './views/pages/Auth/ResetPassword';
// import SettlementsHistory from './views/pages/SettlementsHistory';
import SettlementsHistory from './views/pages/history/SettlementsHistory';
// import SettlementSetup from './views/pages/SettlementSetup';
import SettlementSetup from './views/pages/settlements/SettlementSetup';
// import PreviousCustomers from './views/pages/PreviousCustomers';
import CustomerPayments from './views/pages/CustomerPayments';
import UpdateResult from './views/pages/UpdateResult';
import NewUpdate from './views/pages/utilityPages/NewOwnerUpdate';
import NewCustomerUpdate from './views/pages/customer/UpdateCustomerDetails';
import BulkRegisteration from './views/pages/utilityPages/BulkRegisteration';
import Register from './views/pages/utilityPages/NewCustomer';
// import CreateUser from './views/pages/utilityPages/CreateUser';
import CreateUser from './views/pages/user/CreateUser';
// import AllUsers from './views/pages/utilityPages/AllUsers';
import Users from './views/pages/user/Users';
// import UpdateUser from './views/pages/utilityPages/UpdateUser';
import UpdateUser from './views/pages/user/UpdateUser';
import PreviousCustomers from './views/pages/customer/PreviousCustomer';
import History from './views/pages/history/History';
import CreateAgent from "./views/pages/agent/CreateAgent";
import Agents from "./views/pages/agent/Agents";
import UpdateAgent from "./views/pages/agent/UpdateAgent";


var routes = [
  {
    path: '/index',
    name: 'Dashboard',
    icon: 'ni ni-tv-2 text-primary',
    component: Index,
    layout: '/admin',
    keyword: 'dashboard',
  },
  {
    path: '/index',
    name: 'Dashboard',
    icon: 'ni ni-tv-2 text-primary',
    component: Index,
    layout: '/utility',
    type: 'agent',
    keyword: 'dashboard',
  },

  {
    path: '/register-customer',
    name: 'Register Customer',
    // icon: "ni ni-planet text-blue",
    component: RegisterCustomer,
    layout: '/admin',
    keyword: 'customer',
  },
  {
    path: '/register-customer',
    name: 'Register Single Customer',
    // icon: "ni ni-planet text-blue",
    component: Register,
    layout: '/utility',
    type: 'agent',
    keyword: 'customer',
  },
  {
    path: '/create-user',
    name: 'Create User',
    // icon: "ni ni-planet text-blue",
    component: CreateUser,
    layout: '/utility',
    type: 'users',
  },
  {
    path: '/all-users',
    name: 'View Users',
    // icon: "ni ni-planet text-blue",
    component: Users,
    layout: '/utility',
    type: 'users',
  },
  {
    path: '/user-update/:id',
    icon: 'ni ni-planet text-blue',
    component: UpdateUser,
    layout: '/utility',
  },

  // {
  //   path: "/register-bulk-customers",
  //   name: "Register Customer in Bulk",
  //   // icon: "ni ni-planet text-blue",
  //   component: BulkRegisteration,
  //   layout: "/utility",
  //   keyword: "customer"
  // },
  {
    path: '/customer-update',
    component: NewCustomerUpdate,
    layout: '/utility',
    type: 'agent',
  },
  {
    path: '/customer-update',
    component: NewCustomerUpdate,
    layout: '/admin',
  },
  {
    path: '/customer-payments/:customer_ref',
    component: CustomerPayments,
    layout: '/admin',
  },
  {
    path: '/customer-payments/:customer_ref',
    component: CustomerPayments,
    layout: '/utility',
    type: 'agent',
  },

  {
    path: '/account-result',
    component: AccountResult,
    layout: '/admin',
  },
  {
    path: '/account-result',
    component: AccountResult,
    layout: '/utility',
    type: 'agent',
  },
  {
    path: '/update-result',
    component: UpdateResult,
    layout: '/admin',
  },
  {
    path: '/update-result',
    component: UpdateResult,
    layout: '/utility',
    type: 'agent',
  },

  // {
  //   path: "/user-profile",
  //   name: "Admin Profile",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: Profile,
  //   layout: "/admin"
  // },
  {
    path: '/customer-profile/:id',
    component: CustomerProfile,
    layout: '/admin',
  },
  {
    path: '/customer-profile/:id',
    component: CustomerProfile,
    layout: '/utility',
    type: 'agent',
  },
  {
    path: '/payments',
    name: 'Payments History',
    // icon: "ni ni-bullet-list-67 text-blue",
    component: History,
    layout: '/admin',
    keyword: 'history',
  },
  {
    path: '/payments',
    name: 'Payments History',
    // icon: "ni ni-bullet-list-67 text-blue",
    component: History,
    layout: '/utility',
    type: 'agent',
    keyword: 'history',
  },
  {
    path: '/payments-list',
    name: 'Payments',
    // icon: "ni ni-bullet-list-67 text-blue",
    component: History,
    layout: '/utility',
    type: 'representative',
    keyword: 'history',
  },
  {
    path: '/customers',
    name: 'Manage Customers',
    // icon: "ni ni-bullet-list-67 text-blue",
    component: Customers,
    layout: '/admin',
    keyword: 'customer',
  },
  {
    path: '/customers',
    name: 'Manage Customers',
    // icon: "ni ni-bullet-list-67 text-blue",
    component: Customers,
    layout: '/utility',
    type: 'agent',
    keyword: 'customer',
  },
  {
    path: '/reset-password',
    component: AdminResetPassword,
    layout: '/admin',
  },
  {
    path: '/reset-password',
    component: AdminResetPassword,
    layout: '/utility',
    type: 'agent',
  },
  {
    path: '/settlements-history',
    name: 'Settlements History',
    component: SettlementsHistory,
    layout: '/admin',
    // icon: "ni ni-bullet-list-67 text-blue",
    keyword: 'history',
  },
  {
    path: '/settlements-history',
    name: 'Settlements History',
    component: SettlementsHistory,
    layout: '/utility',
    type: 'agent',
    // icon: "ni ni-bullet-list-67 text-blue",
    keyword: 'history',
  },
  {
    path: '/settlements',
    component: SettlementSetup,
    layout: '/admin',
  },
  {
    path: '/settlements',
    component: SettlementSetup,
    layout: '/utility',
    type: 'agent',
  },
  {
    path: '/previous-profile/:ref',
    component: PreviousCustomers,
    layout: '/admin',
  },
  {
    path: '/previous-profile/:ref',
    component: PreviousCustomers,
    layout: '/utility',
    type: 'agent',
  },
  {
    path: '/create-agent',
    name: 'Register Agent',
    // icon: "ni ni-planet text-blue",
    component: CreateAgent,
    layout: '/utility',
    keyword: 'agent',
  },
  {
    path: '/agents',
    name: 'View Agents',
    // icon: "ni ni-planet text-blue",
    component: Agents,
    layout: '/utility',
    keyword: 'agent',
  },
  {
    path: '/agent-update/:id',
    icon: 'ni ni-planet text-blue',
    component: UpdateAgent,
    layout: '/utility',
  },
];
export default routes;
