import Cookie from 'js-cookie';

export function getCookieValue(key) {
  return Cookie.get(key);
}

export function clearCookieValue(key) {
  return Cookie.remove(key);
}

export function saveCookie(key, value) {
  Cookie.set(key, value, { expires: 7 });
}

export const validateEmail = (email) => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const capitalize = (str) => {
  return str.toLowerCase().replace(/(?<= )[^\s]|^./g, a=>a.toUpperCase())
}
