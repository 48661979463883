import React, { useState, useEffect } from 'react';
import Loader from '../Loader';
import Swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import swalReact from '@sweetalert/with-react';
import NoPaymentHistory from '../NoPaymentHistory';
import ListPagination from '../Pagination';
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Col,
  Table,
  Container,
  Row,
  FormGroup,
  Label,
  Form,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import {
  getSettlements,
  getDateSettlements,
  updateCurrentPage,
  getSettlementsExport
} from './../../../redux/actions/history';
import { nextPage } from './../../../redux/actions/customer';
import { useHistory } from 'react-router-dom';
import ReactExport from 'react-export-excel';
import { useRef } from 'react';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const SettlementsHistory = () => {
  const dispatch = useDispatch();
  const history = useHistory()
  const settlements = useSelector((state) => state.history.settlements);
  const numOfPages = useSelector((state) => state.history.numberOfPages);
  const page = useSelector((state) => state.history.currentPage);
  const exportData = useSelector((state) => state.history.settlementsExport);

  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('No record found');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentPage, setCurrentPage] = useState(false)
  const [isExportLoading, setIsExportLoading] = useState(false)
  const mountedRef = useRef()

  const user = sessionStorage.getItem('type');
  const admin =
    sessionStorage.getItem('role') === 'admin' ||
    sessionStorage.getItem('role') === 'management';
  const token = sessionStorage.getItem('token');
  const passcode = CryptoJS.HmacSHA512(token, '123NDSx').toString();



  const toggle = () => {
    setModal(!modal);
  };

  const handleStartChange = (e) => {
    setStartDate(e.target.value);
  };
  const handleEndChange = (e) => {
    setEndDate(e.target.value);
  };
  // const handleNextPage = () => {
  //   dispatch(nextPage(page + 1));
  // };
  // const handleNewPage = (newPage) => {
  //   dispatch(nextPage(newPage));
  // };

  // const handleBackPage = () => {
  //   dispatch(nextPage(page - 1));
  // };

  const handleNextPage = () => {
    handleFetch("next")
    // dispatch(updateCurrentPage(page + 1, history, () => {}));
  };

  const handleNewPage = (newPage) => {
    handleFetch(newPage)
    // dispatch(updateCurrentPage(newPage, history, () => {}));
  };

  const handleBackPage = () => {
    handleFetch("prev")
    // dispatch(updateCurrentPage(page - 1, history, () => {}));
  };

  const formatAmount = (amount) => {
    return parseFloat(amount).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  // const payload = {};

  const handleFetch = (param, isSort, isExport)=>{

    if(isExport){
      setIsExportLoading(true)
    }else{
      setLoading(true);
    }

    let payload = {}
    if(param === "next"){
      payload.page = currentPage +1
    }else if(param==="prev"){
      payload.page = currentPage -1
    }else if(param && !isSort){
      payload.page = param
    }else{
      payload.page = 1
    }

    payload.token = token;
    payload.passcode = passcode;
    payload.action =
      user === 'service_provider' && !admin
        ? 'get_agent_settlements'
        : 'get_settlements';
        payload.start_date = startDate;
        payload.end_date = endDate;
        payload.paginated = "yes"

    if(isExport){
      payload.paginated = "no"
      dispatch(getSettlementsExport(payload, () => {
        setIsExportLoading(false);
      }));
      return
    }

    dispatch(getSettlements(payload, () => {
      setLoading(false);
    }));

  }

  const handleReset = ()=>{
    setCurrentPage(1)
    setStartDate('')
    setEndDate('')
  }

  
  const handleExport = ()=>{
    handleFetch('','',true)
  }

  useEffect(()=>{
    if(!mountedRef.current){
      handleFetch(1)
      return
    }
    if(startDate || endDate || currentPage !==1) return
    handleFetch()
  },[startDate, endDate, currentPage])

  

  useEffect(() => {
    if(!mountedRef.current){
        mountedRef.current = true
        return
    }
    if (exportData && exportData.length) {
      const btn = document.getElementById('export_button')
      setTimeout(()=>{
        btn && btn.click()
      },1000) 
    }
    if(exportData && exportData.length===0){
      Swal.fire({
        icon: 'info',
        title: 'Oops',
        text: 'No data to export',
      })
    }
  }, [exportData]);

  useEffect(()=>{
    setCurrentPage(page)
  },[page])

  // useEffect(() => {
  //   payload.token = token;
  //   payload.passcode = passcode;
  //   payload.action =
  //     user === 'service_provider' && !admin
  //       ? 'get_agent_settlements'
  //       : 'get_settlements';
  //   payload.page = isMounted?page:1;
  //   setLoading(true);
  //   dispatch(getSettlements(payload, () => {
  //     !isMounted&&setIsMounted(true);
  //     setLoading(false);
  //   }));
  // }, [dispatch, page]);

  const dateSortSettlements = () => {
    // const newPage = page ? page : 1;
    // setLoading(true);
    setModal(!modal);
    // const data = {};
    // data.action = 'get_settlements';
    // data.page = newPage;
    // data.token = token;
    // data.passcode = passcode;
    // data.start_date = startDate;
    // data.end_date = endDate;
    handleFetch('',true)

    // dispatch(getDateSettlements(data, () => setLoading(false)));
  };

  const handleViewMore = (settlement) => {
    swalReact({
      content: (
        <Col xl="14">
          <Card className="shadow align-innertext">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <div className="col">
                  <div></div>
                  <h3 className="mb-0">Settlement Details</h3>
                </div>
              </Row>
            </CardHeader>
            <Table className="align-items-center table-flush" responsive>
              <tbody>
                <tr>
                  <th scope="row">Settlement ID</th>
                  <td>{settlement.settlement_id}</td>
                </tr>
                <tr>
                  <th scope="row">Payment Ref</th>
                  <td>{settlement.payment_ref}</td>
                </tr>
                <tr>
                  <th scope="row">Transaction ID</th>
                  <td>{settlement.transaction_id}</td>
                </tr>
                <tr>
                  <th scope="row">Amount Collected</th>
                  <td>{formatAmount(settlement.amount_collected)}</td>
                </tr>

                <tr>
                  <th scope="row">Amount Settled</th>
                  <td>{formatAmount(settlement.amount_settled)}</td>
                </tr>
                <tr>
                  <th scope="row">Service Charge</th>
                  <td>{formatAmount(settlement.service_charge)}</td>
                </tr>
                <tr>
                  <th scope="row">Date and Time</th>
                  <td>{settlement.timestamp}</td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </Col>
      ),
    });
  };

  return  !settlements && !loading ? (
    <NoPaymentHistory message={message} title="Settlements History" />
  ) : (
    <div>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8"></div>

      <Container className="mt--9" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="row">
                  <Form inline>
                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                      <Label for="exampleEmail" className="mr-sm-2">
                        Start Date
                      </Label>
                      <Input
                        type="date"
                        name="startDate"
                        id="startDate"
                        placeholder="Select start date"
                        value={startDate}
                        onChange={handleStartChange}
                      />
                    </FormGroup>
                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                      <Label for="examplePassword" className="mr-sm-2">
                        End Date
                      </Label>
                      <Input
                        type="date"
                        name="endDate"
                        id="endDate"
                        placeholder="Select end date"
                        value={endDate}
                        onChange={handleEndChange}
                      />
                    </FormGroup>
                    <Button disabled={loading} onClick={dateSortSettlements} style={{width:150}} className="mb-2 ml-2">Sort</Button>
                    <Button onClick={handleReset} style={{width:150}} className="mb-2 ml-2">Reset</Button>
                    <ExcelFile
                        filename="Settlement History"
                        element={
                          <button  id="export_button" className="d-none">
                            <i className="fas fa-download" />
                            Export
                          </button>
                        }
                      >
                        <ExcelSheet data={exportData} name="Settlement History">
                          <ExcelColumn
                            label="Settlement ID"
                            value="settlement_id"
                          />
                          <ExcelColumn
                            label="Amount Collected"
                            value="amount_collected"
                          />
                              <ExcelColumn
                            label="Amount Settled"
                            value="amount_settled"
                          />
                          <ExcelColumn
                            label="Payment Reference"
                            value="payment_ref"
                          />
                          <ExcelColumn
                            label="Date and Time"
                            value="timestamp"
                          />
                        </ExcelSheet>
                  </ExcelFile>
                  <Button color="success" className="mr-3 mb-2" onClick={handleExport} disabled={isExportLoading}>
                      {isExportLoading? <i className="fas fa-spinner fa-spin"></i>:<i className="fas fa-download" />}
                        Export
                      </Button>
                  </Form>
                </div>
                <br />
                <br />

                {loading && <div className='text-center'>
                  <i className="fas fa-spinner fa-spin"></i>
                </div>}
             
                <h3 className="mb-0">Settlements</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Settlement ID</th>
                    <th scope="col">Amount Collected</th>
                    <th scope="col">Amount Settled</th>
                    <th scope="col">Payment Reference</th>
                    <th scope="col">Date and Time</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {settlements.map((settlement, i) => {
                    return (
                      <tr key={i}>
                        <th scope="row">{settlement.settlement_id}</th>

                        <td>{formatAmount(settlement.amount_collected)}</td>
                        <td>{settlement.amount_settled}</td>
                        <td>{settlement.payment_ref}</td>
                        <td>{settlement.timestamp}</td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                onClick={() => handleViewMore(settlement)}
                              >
                                View More
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <CardFooter className="py-4">
              {loading && <div className='text-center'>
                  <i className="fas fa-spinner fa-spin"></i>
                </div>}
                <nav aria-label="...">
                  <ListPagination
                    numberOfPages={numOfPages}
                    currentPage={page}
                    handleBackPage={handleBackPage}
                    handleNextPage={handleNextPage}
                    handleNewPage={handleNewPage}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default SettlementsHistory;
