import React from 'react';
import { Redirect, Route } from 'react-router-dom';


export default function UsersWrapper({ routes }) {

  const listRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin" && sessionStorage.getItem('type') === 'other') {
        return (
          <Route 
            exact
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } 
      // else {
      //   return <Redirect to="/notfound" />;
      // }
    });
  }

  return (
    <>
    {listRoutes(routes)}
   </>
  )
}