import React, { useState, useEffect } from "react";
import CryptoJS from 'crypto-js';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
import { useDispatch, useSelector } from 'react-redux';
import { getAgent, updateAgent, clearUpdateField } from './../../../redux/actions/agent';
import { useHistory } from 'react-router-dom';

const UpdateAgent = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const token = sessionStorage.getItem('token');
  const passcode = CryptoJS.HmacSHA512(token, '123NDSx').toString();
  const dispatch = useDispatch();
  const history = useHistory();
  const agentEmail = useSelector((state) => state.agent.agent_email);
  const agentPhone = useSelector((state) => state.agent.agent_phone);
  const agentName = useSelector((state) => state.agent.agent_name);
  const agentId = useSelector((state) => state.agent.agent_id);
  const [value, setValue] = useState({
    agentPhone: '',
    agentName: '',
    agentEmail: ''
  });

  useEffect(() => {
    setIsLoading(true)
    const { id } = props.match.params;
    const data = {
      token,
      action: 'get_agent',
      agent_id: id,
      passcode: passcode
    }
    dispatch(
      getAgent(data, history, () =>  setIsLoading(false))
    );
    return () => {
      dispatch(clearUpdateField())
    }
  }, [dispatch]);

  const handleSumbit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = {
      action: 'edit_agent',
      passcode,
      token,
      agent_email: value.agentEmail !== "" ? value.agentEmail : agentEmail,
      agent_name: value.agentName !== "" ? value.agentName : agentName,
      agent_phone: value.agentPhone !== "" ? value.agentPhone : agentPhone,
      agent_id: value.agent_id !== "" ? value.agent_id : agentId,
    }
    dispatch(updateAgent(data, history, () => setIsLoading(false)));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValue((prevState) => ({
      ...prevState,
      [name]: value,
      agent_id: agentId
    }));
  }

  return (
    <>
      {/* <UserHeader /> */}
      <div
        className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        style={{
          minHeight: "300px"
        }}
      >
        {/* Mask */}
        <span className="mask bg-gradient-default opacity-8" />
        {/* Header container */}

      </div>


      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <Card className="card-Customer shadow">
              <CardBody className="pt-0 pt-md-4">
                <Row>
                </Row>
                <div className="text-center">
                  <h3>
                    {agentName}
                    <span className="font-weight-light"></span>
                  </h3>
                  <div className="h5 font-weight-300">
                    <i className="ni location_pin mr-2" />
                    {agentEmail}
                  </div>
                  <div className="h5 font-weight-300">
                    <i className="ni location_pin mr-2" />
                    {agentPhone}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Edit Agents Details</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    Agent information
                    </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Name
                            </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={agentName}
                            id="input-name"
                            placeholder={agentName}
                            type="text"
                            name="agentName"
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Phone Number
                            </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={agentPhone}
                            id="input-phone"
                            placeholder={agentPhone}
                            type="text"
                            name="agentPhone"
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email address
                            </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={agentEmail}
                            id="input-email"
                            placeholder={agentEmail}
                            type="email"
                            name="agentEmail"
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Container className="d-flex align-items-center" fluid>
                      <Row>
                        <Col lg="7" md="10">
                          <Button
                            color="info"
                            onClick={handleSumbit}
                            disabled={isLoading}
                          >
                            {isLoading ? <span><i className="fas fa-spinner fa-spin"></i>Please wait...</span> : 'Update Changes'}
                          </Button>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UpdateAgent;
