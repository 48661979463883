import React from "react";
import Swal from 'sweetalert2';
import CryptoJS from "crypto-js";
import Loader from './Loader';
import swalReact from '@sweetalert/with-react';
import NoPaymentHistory from './NoPaymentHistory'
import ListPagination from './Pagination';
import {
  Badge,
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Container,
  Row,
  Col,
  CardFooter,
} from "reactstrap";




class CustomerPayments extends React.Component {
    constructor(props) {
      super(props);
 this.state = {
    paymentsLoading: false,
    customer_reference:'',
    searched:'',
    payments: [],
    response: {},
    clear:false,
    numberOfPages: 1,
    currentPage: 1,
    message: 'No record found',
    customer_ref: this.props.match.params.customer_ref,
    type: sessionStorage.getItem('type'),
  }
  }



  handleNextPage = () => {
    this.setState((prevState) => ({
      currentPage: prevState.currentPage + 1
    }))

  }

  handleNewPage = (newPage) => {
    this.setState({
      currentPage: newPage
    });

  }

  handleBackPage = () => {
    this.setState((prevState) => ({
      currentPage: prevState.currentPage - 1
    }))
  }

  

  formatAmount = (amount) => {
    return parseFloat(amount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  }

  componentDidMount() {
    this.searchResult(this.state.currentPage);
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.currentPage !== this.state.currentPage) {
      this.searchResult(this.state.currentPage);
    }
  };

  searchResult = async () => {
    try {
      this.setState({ paymentsLoading: true })
      this.setState({ clear: true })
      const token = sessionStorage.getItem('token')
      const passcode = CryptoJS.HmacSHA512(token, "123NDSx").toString();
      const data = { token, passcode, action: 'get_payments', customer_ref: this.state.customer_ref }
      console.log(data)
    
      const response = await fetch('https://irecharge.com.ng/api/adept/get_payments.php', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
      const responseData = await response.json()
      if (responseData.status === '0') {
        return this.setState({
          payments: responseData.items,
          paymentsLoading: false,
          response
        });

      } else if (responseData.status === '-2') {
        return Swal.fire({
          icon: 'info',
          title: responseData.message,
          text: 'Please login again',
          timer: 2000,
          onClose: () => {
            this.props.history.push('/auth/login')
          }
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            this.props.history.push('/auth/login')
          }
        })
      }
      else {
        this.setState({ paymentsLoading: false });
        this.setState({ clear: false })
        return Swal.fire({
          icon: 'info',
          text: responseData.message
        })
      }
    } catch (err) {
      this.setState({ paymentsLoading: false, message: 'Unable to load' });
      return Swal.fire({
        icon: 'error',
        title: 'Unable to load payments',
        text: 'Please check your internet connection and try again or contact support'
      })
    }
  };

  handleViewMore = (payment) => {



    swalReact({

      content: <Col xl="14">
        <Card className="shadow align-innertext">
          <CardHeader className="border-0">
            <Row className="align-items-center">
              <div className="col">
                <h3 className="mb-0">Payment Details</h3>
              </div>
              <div className="col text-right">
                <Badge color="success">Fulfilled</Badge>
              </div>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>

            <tbody>
              <tr>
                <th scope="row">Bank</th>
                <td>{payment.bank}</td>
              </tr>
              <tr>
                <th scope="row">Account Number</th>
                <td>{payment.account_number}</td>

              </tr>
              <tr>
                <th scope="row">Payment Ref</th>
                <td>{payment.payment_ref}</td>

              </tr>
              {this.state.type === 'service_provider' && <tr>
                <th scope="row">Utility Ref</th>
                <td>{payment.utility_ref}</td>

              </tr>}
              <tr>
                <th scope="row">Transaction ID</th>
                <td>{payment.transaction_id}</td>

              </tr>
              <tr>
                <th scope="row">Amount Paid</th>
                <td>{this.formatAmount(payment.amount)}</td>

              </tr>

              <tr>
                <th scope="row">Amount Dispensed</th>
                <td>{this.formatAmount(payment.amount_dispense)}</td>

              </tr>

              <tr>
                <th scope="row">Service Charge</th>
                <td>{this.formatAmount(payment.service_charge)}</td>

              </tr>

              <tr>
                <th scope="row">Payment ID</th>
                <td>{payment.payment_id}</td>

              </tr>

              <tr>
                <th scope="row">Payment Mode</th>
                <td>{payment.source}</td>

              </tr>
              <tr>
                <th scope="row">Timestamp</th>
                <td>{payment.timestamp}</td>

              </tr>


            </tbody>
          </Table>


        </Card>
      </Col>
    })



  }
  render() {
    return (this.state.paymentsLoading ? <Loader title="Payment History" /> : (!this.state.payments.length ? <NoPaymentHistory message={this.state.message} title="Payment History" /> :
      <>
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8"></div>
        {/* Page content */}
        <Container className="mt--9" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Payment History</h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Account Name</th>
                      <th scope="col">Amount Dispensed</th>
                      <th scope="col">Payment Reference</th>
                      {
                      this.state.type === 'service_provider' && 
                      <th scope="col">Utility Reference</th>
                      }
                      <th scope="col">Transaction ID</th>
                      <th scope="col">Time stamp</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.state.payments.map((payment, index) => {
                        return (
                          <tr key={index}>
                            <th scope="row">
                              {payment.account_name}
                            </th>

                            <td>
                              {this.formatAmount(payment.amount_dispense)}
                            </td>
                            <td>
                              {payment.payment_ref}
                            </td>
                            {this.state.type === 'service_provider' && <td>
                              {payment.utility_ref}
                            </td>}
                            <td>
                              {payment.transaction_id}
                            </td>
                            <td>
                              {payment.timestamp}
                            </td>
                            <td className="text-right">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  role="button"
                                  size="sm"
                                  color=""
                                  onClick={e => e.preventDefault()}
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-arrow" right>
                                  <DropdownItem

                                    onClick={() => this.handleViewMore(payment)}
                                  >
                                    View More
                            </DropdownItem>

                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </Table >
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <ListPagination
                      numberOfPages={this.state.numberOfPages}
                      currentPage={this.state.currentPage}
                      handleBackPage={this.handleBackPage}
                      handleNextPage={this.handleNextPage}
                      handleNewPage={this.handleNewPage}
                    />
                  </nav>
                </CardFooter>

              </Card>
            </div>
          </Row>

        </Container>
      </>
    ))
  }
}

export default CustomerPayments;
