import {
  CUSTOMERS_LIST,
  EDIT_CUSTOMER,
  NEXT_PAGE,
  PREVIOUS_PAGE,
  UPDATE_CUSTOMER_DETAILS,
  SAVE_CUSTOMER,
  CLEAR_CUSTOMER
} from '../actions/actionTypes';

const initialState = {
  isLoading: false,
  searchTerm: '',
  numberOfPages: 1,
  currentPage: 1,
  searchedDetailActive: false,
  searchActive: false,
  customer: [],
  customer_name: '',
  customer_email: '',
  customer_phone: '',
  customer_address: '',
  customer_ref: '',
  customer_id: ''
};

export const CustomerReducer = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMERS_LIST:
      return {
        ...state,
        searchActive: false,
        customer: action.items,
        currentPage: action.current_page,
        numberOfPages: action.total_pages,
        // isLoading: false,
      };
      case SAVE_CUSTOMER:
        return {
          ...state,
          customer_name: action.customer.customer_name,
          customer_email: action.customer.customer_email,
          customer_phone: action.customer.customer_phone,
          customer_address: action.customer.customer_address,
          customer_ref: action.customer.customer_ref,
          customer_id: action.customer.customer_id
        };
      case CLEAR_CUSTOMER:
        return {
          ...state,
          customer_name: '',
          customer_email: '',
          customer_phone: '',
          customer_address: '',
          customer_ref: '',
          customer_id: ''
        };
    case NEXT_PAGE:
      return {
        ...state,
        currentPage: action.page,
      };

    case UPDATE_CUSTOMER_DETAILS:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};
