import React, { useState, useEffect } from "react";
import CryptoJS from 'crypto-js';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
import { useDispatch, useSelector } from 'react-redux';
import { getCustomer, clearUpdateField } from './../../../redux/actions/customer';
import { saveEditedProfile } from './../../../redux/actions/customerProfile';
import { useHistory } from 'react-router-dom';

const UpdateCustomer = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const token = sessionStorage.getItem('token');
  const passcode = CryptoJS.HmacSHA512(token, '123NDSx').toString();
  const dispatch = useDispatch();
  const history = useHistory();
  const customerEmail = useSelector((state) => state.customer.customer_email);
  const customerPhone = useSelector((state) => state.customer.customer_phone);
  const customerName = useSelector((state) => state.customer.customer_name);
  const customerAddress = useSelector((state) => state.customer.customer_address);
  const customerPhone1 = useSelector((state) => state.customer.customer_phone1);
  const customerPhone2 = useSelector((state) => state.customer.customer_phone2);
  const customerRef = useSelector((state) => state.customer.customer_ref);
  const customerId = useSelector((state) => state.customer.customer_id);
  const [value, setValue] = useState({
    customerPhone: '',
    customerName: '',
    customerEmail: '',
    customerAddress:'',
    customerPhone1:'',
    customerPhone2:'',
    customerRef:'',
    customer_id:'',
  });

  useEffect(() => {
    setIsLoading(true)
    const { id } = props.match.params;
    const data = {
      token,
      action: 'get_customer',
      customer_id: id,
      passcode: passcode
    }
    dispatch(
      getCustomer(data, history, () =>  setIsLoading(false))
    );
    return () => {
      dispatch(clearUpdateField())
    }
  }, [dispatch]);

  const handleSumbit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = {
      action: 'edit_customer',
      passcode,
      token,
      customer_email: value.customerEmail !== "" ? value.customerEmail : customerEmail,
      customer_name: value.customerName !== "" ? value.customerName : customerName,
      customer_phone: value.customerPhone !== "" ? value.customerPhone : customerPhone,
      customer_address: value.customerAddress !== "" ? value.customerAddress : customerAddress,
      customer_phone1: value.customerPhone1 !== "" ? value.customerPhone1 : customerPhone1,
      customer_phone2: value.customerPhone2 !== "" ? value.customerPhone2 : customerPhone2,
      customer_ref: value.customerRef !== "" ? value.customerRef : customerRef,
      customer_id: value.customer_id !== "" ? value.customer_id : customerId,
    }
    dispatch(saveEditedProfile(data, history, () => setIsLoading(false)));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValue((prevState) => ({
      ...prevState,
      [name]: value,
      customer_id: customerId,
      customerRef
    }));
  }

  return (
    <>
      {/* <UserHeader /> */}
      <div
        className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        style={{
          minHeight: "300px"
        }}
      >
        {/* Mask */}
        <span className="mask bg-gradient-default opacity-8" />
        {/* Header container */}

      </div>


      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <Card className="card-Customer shadow">
              <CardBody className="pt-0 pt-md-4">
                <Row>
                </Row>
                <div className="text-center">
                  <h3>
                    {customerName}
                    <span className="font-weight-light"></span>
                  </h3>
                  <div className="h5 font-weight-300">
                    <i className="ni location_pin mr-2" />
                    {customerEmail}
                  </div>
                  <div className="h5 font-weight-300">
                    <i className="ni location_pin mr-2" />
                    {customerPhone}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Edit customers Details</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    customer information
                    </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Name
                            </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={customerName}
                            id="input-name"
                            placeholder={customerName}
                            type="text"
                            name="customerName"
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Customer ref
                            </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={customerRef}
                            id="customer_ref"
                            placeholder={customerRef}
                            type="text"
                            name="customerRef"
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email address
                            </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={customerEmail}
                            id="input-email"
                            placeholder={customerEmail}
                            type="email"
                            name="customerEmail"
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Phone Number
                            </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={customerPhone}
                            id="customer_phone"
                            placeholder={customerPhone}
                            type="email"
                            name="customerPhone"
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-number"
                          >
                           First Phone Number 
                            </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={customerPhone1}
                            id="input-email"
                            placeholder={customerPhone1}
                            type="number"
                            name="customerPhone1"
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Second Phone Number 
                            </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={customerPhone2}
                            id="customer_phone"
                            placeholder={customerPhone2}
                            type="number"
                            name="customerPhone2"
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-text"
                          >
                            Address
                            </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={customerAddress}
                            id="input-text"
                            placeholder={customerAddress}
                            type="text"
                            name="customerAdress"
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                     
                    </Row>
                    <Container className="d-flex align-items-center" fluid>
                      <Row>
                        <Col lg="7" md="10">
                          <Button
                            color="info"
                            onClick={handleSumbit}
                            disabled={isLoading}
                          >
                            {isLoading ? <span><i className="fas fa-spinner fa-spin"></i>Please wait...</span> : 'Update Changes'}
                          </Button>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UpdateCustomer;
