/*eslint-disable*/
import React from 'react';
import { NavLink as NavLinkRRD, Link, withRouter } from 'react-router-dom';
// nodejs library to set properties for components
import { PropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from 'reactstrap';
import routes from '../../routes';

var ps;

const btnDropdown = {
  position: 'absolute',
  right: 0,
  top: '50%',
  transform: 'translate(0, -50%)',
};

class Sidebar extends React.Component {
  state = {
    collapseOpen: false,
    hideAgent: false,
    hideUser: false,
    hideCustomer: false,
    hideHistory: false,
    activeDashboard: '', // get the loggedIn user dashboard
  };
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  }
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };

  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false,
    });
  };
  // creates the links that appear in the left menu / Sidebar
  createSidebarLinks = (routes) => {
    const admin =
      sessionStorage.getItem('role') === 'admin' ||
      sessionStorage.getItem('role') === 'management';
    let actualRoutes;
    if (sessionStorage.getItem('type') === 'service_provider' && admin) {
      actualRoutes = routes.filter((element) => element.layout === '/utility');
    } else if (
      sessionStorage.getItem('type') === 'service_provider' &&
      !admin
    ) {
      actualRoutes = routes.filter(
        (element) => element.layout === '/utility' && element.type === 'agent'
      );
    } else {
      actualRoutes = routes.filter((element) => element.layout === '/admin');
    }
    return actualRoutes.map((prop, key) => {
      return (
        prop.name && (
          <NavItem key={key}>
            <NavLink
              to={prop.layout + prop.path}
              tag={NavLinkRRD}
              onClick={this.closeCollapse}
              activeClassName="active"
            >
              <i className={prop.icon} />
              {prop.name}
            </NavLink>
          </NavItem>
        )
      );
    });
  };
  // create the dashboard navigationn from the list of routes
  createDashboardLinks = (routes) => {
    const admin =
      sessionStorage.getItem('role') === 'admin' ||
      sessionStorage.getItem('role') === 'management';
    var actualRoutes;
    if (sessionStorage.getItem('type') === 'service_provider' && admin) {
      actualRoutes = routes.filter((element) => element.layout === '/utility');
    } else if (
      sessionStorage.getItem('type') === 'service_provider' &&
      !admin
    ) {
      actualRoutes = routes.filter(
        (element) => element.layout === '/utility' && element.type === 'agent'
      );
    } else {
      actualRoutes = routes.filter((element) => element.layout === '/admin');
    }
    // Fetch just the dashboard from the list of allowed user routes
    actualRoutes = actualRoutes.find((c) => c.keyword === 'dashboard');

    // set the new dahsbord link to the activeDashboard
    this.setState({
      activeDashboard: actualRoutes.layout + actualRoutes.path,
    });
  };
  componentDidMount() {
    this.createDashboardLinks(routes);
  }
  // create the customer navigationn from the list of routes for the customer dropdown list
  createCustomerLinks = (routes) => {
    const admin =
      sessionStorage.getItem('role') === 'admin' ||
      sessionStorage.getItem('role') === 'management';
    let actualRoutes;
    if (sessionStorage.getItem('type') === 'service_provider' && admin) {
      actualRoutes = routes.filter((element) => element.layout === '/utility');
    } else if (
      sessionStorage.getItem('type') === 'service_provider' &&
      !admin
    ) {
      actualRoutes = routes.filter(
        (element) => element.layout === '/utility' && element.type === 'agent'
      );
    } else {
      actualRoutes = routes.filter((element) => element.layout === '/admin');
    }
    // get only the routes that are grouped as customer
    actualRoutes = actualRoutes.filter((c) => c.keyword === 'customer');
    if (actualRoutes.length !== 0) {
      return actualRoutes.map((prop, key) => {
        return (
          prop.name && (
            <DropdownItem
              key={key}
              to={prop.layout + prop.path}
              tag={NavLinkRRD}
              activeClassName="active"
            >
              <i className={prop.icon} />
              <span>{prop.name}</span>
            </DropdownItem>
          )
        );
      });
    } else {
      // hide the customer dropdown if there are no sub-menu
      this.setState({
        hideCustomer: !this.state.hideCustomer,
      });
    }
  };
  // create the agent navigationn from the list of routes for the agent dropdown list
  createAgentLinks = (routes) => {
    const admin =
      sessionStorage.getItem('role') === 'admin' ||
      sessionStorage.getItem('role') === 'management';
    let actualRoutes;
    if (sessionStorage.getItem('type') === 'service_provider' && admin) {
      actualRoutes = routes.filter((element) => element.layout === '/utility');
    } else if (
      sessionStorage.getItem('type') === 'service_provider' &&
      !admin
    ) {
      actualRoutes = routes.filter(
        (element) => element.layout === '/utility' && element.type === 'agent'
      );
    } else {
      actualRoutes = routes.filter((element) => element.layout === '/admin');
    }
    // get only the routes that are grouped as agent
    actualRoutes = actualRoutes.filter((c) => c.keyword === 'agent');
    if (actualRoutes.length !== 0) {
      return actualRoutes.map((prop, key) => {
        return (
          prop.name && (
            <DropdownItem
              key={key}
              to={prop.layout + prop.path}
              tag={NavLinkRRD}
              activeClassName="active"
            >
              <i className={prop.icon} />
              <span>{prop.name}</span>
            </DropdownItem>
          )
        );
      });
    } else {
      this.setState({
        hideAgent: !this.state.hideAgent,
      });
    }
  }

  createUserLinks = routes => {
    const admin = sessionStorage.getItem('role') === 'admin' || sessionStorage.getItem('role') === 'management';
    let actualRoutes = [];
    if (sessionStorage.getItem('type') === 'service_provider' && admin) {
      actualRoutes = routes.filter(element => element.type === 'users');
    }
    if (actualRoutes.length !== 0) {
        return actualRoutes.map((prop, key) => {
          return (prop.name &&
            <DropdownItem key={key} to={prop.layout + prop.path} tag={NavLinkRRD} activeClassName="active">
            <i className={prop.icon} />
            <span>{prop.name}</span>
          </DropdownItem>
        );
      });
    } else {
      this.setState({
        hideUser: !this.state.hideUser
      });
    }
  }
  
  createHistoryLinks = routes => {
    const admin = sessionStorage.getItem('role') === 'admin' || sessionStorage.getItem('role') === 'management' ;
    let actualRoutes;
    if (sessionStorage.getItem('type') === 'service_provider' && admin) {
      actualRoutes = routes.filter(element => element.layout === '/utility' && element.type === 'agent');
    } else if (sessionStorage.getItem('type') === 'service_provider' && !admin && sessionStorage.getItem('role') === 'representative') {
      actualRoutes = routes.filter(element => (element.layout === '/utility' && element.type === 'representative'));
    } else if (sessionStorage.getItem('type') === 'service_provider' && !admin) {
      actualRoutes = routes.filter(element => (element.layout === '/utility' && element.type === 'agent'));
    } else {
      actualRoutes = routes.filter((element) => element.layout === '/admin');
    }

    actualRoutes = actualRoutes.filter(c => c.keyword === "history")
    if (actualRoutes.length !== 0) {
      return actualRoutes.map((prop, key) => {
        return (
          prop.name && (
            <DropdownItem
              key={key}
              to={prop.layout + prop.path}
              tag={NavLinkRRD}
              activeClassName="active"
            >
              <i className={prop.icon} />
              <span>{prop.name}</span>
            </DropdownItem>
          )
        );
      });
    } else {
      this.setState({
        hideHistory: !this.state.hideHistory,
      });
    }
  };
  // createLinks = routes => {
  //   return routes.map((prop, key) => {
  //     return (prop.name &&
  //       <NavItem key={key}>
  //         <NavLink
  //           to={prop.layout + prop.path}
  //           tag={NavLinkRRD}
  //           onClick={this.closeCollapse}
  //           activeClassName="active"
  //         >
  //           <i className={prop.icon} />
  //           {prop.name}
  //         </NavLink>
  //       </NavItem>
  //     );
  //   });
  // };

  createManagementLinks = (routes) => {
    const admin =
      sessionStorage.getItem('role') === 'admin' ||
      sessionStorage.getItem('role') === 'management';
    let actualRoutes;
    if (sessionStorage.getItem('type') === 'service_provider' && admin) {
      actualRoutes = routes.filter((element) => element.layout === '/utility');
    } else if (
      sessionStorage.getItem('type') === 'service_provider' &&
      !admin
    ) {
      actualRoutes = routes.filter(
        (element) => element.layout === '/utility' && element.type === 'agent'
      );
    } else {
      actualRoutes = routes.filter((element) => element.layout === '/admin');
    }
    // get only the routes that are grouped as agent
    actualRoutes = actualRoutes.filter((c) => c.forMobile === true);
    if (actualRoutes.length !== 0) {
      return actualRoutes.map((prop, key) => {
        return (
          prop.name && (
            <DropdownItem
              key={key}
              to={prop.layout + prop.path}
              tag={NavLinkRRD}
              activeClassName="active"
            >
              <i className={prop.icon} />
              <span>{prop.name}</span>
            </DropdownItem>
          )
        );
      });
    } else {
      this.setState({
        hideAgent: !this.state.hideAgent,
      });
    }
  }

  render() {
    const { bgColor, routes, logo } = this.props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link,
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: '_blank',
      };
    }
    return (
      <Navbar
        className="navbar-vertical fixed-left navbar-light bg-white"
        expand="md"
        id="sidenav-main"
      >
        <Container fluid>
          {/* Toggler */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={this.toggleCollapse}
          >
            <span className="navbar-toggler-icon" />
          </button>
          {/* Brand */}
          {logo ? (
            // <NavbarBrand className="pt-0" {...navbarBrandProps}>
            <NavbarBrand className="pt-0">
              <a href={`#${this.state.activeDashboard}`}>
                <img
                  alt={logo.imgAlt}
                  className="navbar-brand-img"
                  src={logo.imgSrc}
                />
              </a>
            </NavbarBrand>
          ) : null}
          {/* User */}
          {/* Collapse */}
          <Collapse navbar isOpen={this.state.collapseOpen}>
            {/* Collapse header */}
            <div className="navbar-collapse-header d-md-none">
              <Row>
                {logo ? (
                  <Col className="collapse-brand" xs="6">
                    {logo.innerLink ? (
                      <Link to={`#${this.state.activeDashboard}`}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </Link>
                    ) : (
                      <a href={`#${this.state.activeDashboard}`}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </a>
                    )}
                  </Col>
                ) : null}
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={this.toggleCollapse}
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            {/* Navigation */}
            {/* <Nav navbar>{this.createSidebarLinks(routes)}</Nav> */}
            <Nav navbar>
              {/* {this.createDashboardLinks(routes)} */}
              <NavItem>
                <NavLink
                  to={this.state.activeDashboard}
                  tag={NavLinkRRD}
                  onClick={this.closeCollapse}
                  //activeClassName="active"
                >
                  <i className="ni ni-tv-2 text-primary" />
                  Dashboard
                </NavLink>
              </NavItem>
            </Nav>
            <Nav navbar>
              {
                !this.state.hideCustomer ?
                (
                  <UncontrolledDropdown nav>
                    <DropdownToggle className="pr-0" nav>
                      <i className="ni ni-single-02 text-blue" /> Customer 
                      <i className="ni ni-bold-down" style={btnDropdown}/>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right>
                      {this.createCustomerLinks(routes)}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                ) : ''
              }
              {
                !this.state.hideAgent ? 
                (
                  <UncontrolledDropdown nav>
                    <DropdownToggle className="pr-0" nav>
                      <i className="ni ni-briefcase-24 text-blue" /> Agent 
                      <i className="ni ni-bold-down" style={btnDropdown}/>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right>
                      {this.createAgentLinks(routes)}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                ) : ''
              }
              {
                !this.state.hideUser ? 
                (
                  <UncontrolledDropdown nav>
                    <DropdownToggle className="pr-0" nav>
                      <i className="ni ni-single-02 text-blue" /> Users
                      <i className="ni ni-bold-down" style={btnDropdown}/>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right>
                      {this.createUserLinks(routes)}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                ) : ''
              }
              {
                !this.state.hideHistory ?
                (
                  <UncontrolledDropdown nav>
                    <DropdownToggle className="pr-0" nav>
                      <i className="ni ni-collection text-blue" />
                      History <i className="ni ni-bold-down" style={btnDropdown} />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right>
                      {this.createHistoryLinks(routes)}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                ) : ''
              }
                         {
                !this.state.hideHistory ?
                (
                  <>
                  <UncontrolledDropdown nav>
                    <DropdownToggle className="pr-0 d-block d-md-none" nav>
                      <i className="ni ni-collection text-blue" />
                      Account Management <i className="ni ni-bold-down" style={btnDropdown} />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow " right>
                      {/* {this.createManagementLinks(routes)} */}
                      {
                         (sessionStorage.getItem('role') === 'admin' ||
                         sessionStorage.getItem('role') === 'management') &&
                         <>
                         <DropdownItem  to={'/admin/settlements'} tag={NavLinkRRD} activeClassName="active">
                         <i className={''} />
                         <span>{'Settlements'}</span>
                       </DropdownItem>
                               <DropdownItem  to={'/admin/reset-password'} tag={NavLinkRRD} activeClassName="active">
                               <i className={''} />
                               <span>{'Reset Password'}</span>
                             </DropdownItem>
                             </>
                      }
                      {
                         (!sessionStorage.getItem('role') === 'admin' && !sessionStorage.getItem('role') === 'management') &&
                         <>
                         <DropdownItem  to={'/utility/settlements'} tag={NavLinkRRD} activeClassName="active">
                         <i className={''} />
                         <span>{'Settlements'}</span>
                       </DropdownItem>
                               <DropdownItem  to={'/utility/reset-password'} tag={NavLinkRRD} activeClassName="active">
                               <i className={''} />
                               <span>{'Reset Password'}</span>
                             </DropdownItem>
                             </>
                      }
                    

                    </DropdownMenu>
             

                  </UncontrolledDropdown>
                  <div className="d-block d-md-none" style={{marginTop: 24, paddingLeft:16,paddingTop:8, paddingBottom:8, fontWeight: 500}}>
                  <i className="ni ni-button-power" />
                    <span style={{ cursor:"pointer",paddingLeft:4}} onClick={() => {
                    sessionStorage.clear();
                    this.props.history.push('/auth/login');
                  }}>Logout</span>
                  </div>
                  </>

                ) : ''
              }
            </Nav>
            {/* Divider */}
            <hr className="my-3" />
            {/* Heading */}

            {/* Navigation */}
            <Nav className="mb-md-3" navbar>
              <NavItem>
                <NavLink
                  onClick={() => {
                    sessionStorage.clear();
                    this.props.history.push('/auth/login');
                  }}
                >
                  <i className="ni ni-button-power" />
                  Logout
                </NavLink>
              </NavItem>

              <NavItem className="support">
                <Card className="support-card">
                  <fieldset>
                    {/* <div>
                  <span style={{paddingLeft:16, paddingTop:32, paddingBottom:8, cursor:"pointer"}} onClick={() => {
                    sessionStorage.clear();
                    this.props.history.push('/auth/login');
                  }}>Logout</span>
                  </div> */}
                    <div>Customer Support:</div>
                    <div>
                      <i className="fas fa-envelope-open-text"></i> {'       '}
                      support@irecharge.com.ng
                    </div>
                    <div>
                      <i className="fas fa-phone"></i>
                      {'       '}+2348070791433
                    </div>
                  </fieldset>
                </Card>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default withRouter(Sidebar);
