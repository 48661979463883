import React, { useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';
import { withRouter, Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import swalReact from '@sweetalert/with-react';
import Loader from '../Loader';
import ListPagination from '../Pagination';
import NoPaymentHistory from '../NoPaymentHistory';

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Col,
  Button,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  CustomerList,
  getSearchTerm,
  nextPage,
} from '../../../redux/actions/customer';

const Customer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const customers = useSelector((state) => state.customer.customer);
  const dataPage = useSelector((state) => state.customer.currentPage);
  const pageNum = useSelector((state) => state.customer.numberOfPages);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchActive, setSearchActive] = useState(false);
  const [message, setMessage] = useState('No customers found');
  const [searchedDetailActive, setSearchDetailActive] = useState(false);

  let data = {};
  const sToken = sessionStorage.getItem('token');
  const sKey = CryptoJS.HmacSHA512(
    sessionStorage.getItem('token'),
    '123NDSx'
  ).toString();
  const baseUrl =
    sessionStorage.getItem('type') === 'other' ? '/admin' : '/utility';

  const handleNextPage = () => {
    dispatch(nextPage(dataPage + 1));
  };

  const handleNewPage = (newPage) => {
    setLoading(true);
    dispatch(nextPage(newPage, history, () => setLoading(false)));
  };

  const handleBackPage = () => {
    setLoading(true);
    dispatch(nextPage(dataPage - 1, history, () => setLoading(false)));
  };
  useEffect(() => {
    setLoading(true);
    data.passcode = sKey;
    data.token = sToken;
    data.action = 'get_customers';
    data.page = dataPage;
    dispatch(CustomerList(data, history, () => setLoading(false)));
  }, [dispatch, dataPage]);
  const formValues = {};
  formValues.passcode = sKey;
  formValues.token = sToken;
  formValues.action = 'get_customers';

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    setLoading(true);
    setSearchActive(true);
    formValues.customer_ref = searchTerm;
    dispatch(getSearchTerm(formValues, history, () => setLoading(false)));
  };

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const changeRoute = (name) => {
    history.push(name);
  };

  const handleViewMore = (customer) => {
    swalReact({
      content: (
        <Col xl="14">
          <Card className="shadow align-innertext">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <div className="col">
                  <h3 className="mb-0">Customer details</h3>
                </div>
              </Row>
            </CardHeader>
            <Table className="align-items-center table-flush" responsive>
              <tbody>
                <tr>
                  <th scope="col">customer_ref</th>
                  <td>{customer.customer_ref}</td>
                </tr>
                <tr>
                  <th scope="col">Account Number</th>
                  <td>{customer.account_number}</td>
                </tr>
                <th scope="col">Account Name</th>
                <td>{customer.account_name}</td>
                <tr>
                  <th scope="col">Bank Name</th>
                  <td>{customer.bank}</td>
                </tr>
                <th scope="col">Customer Name</th>
                <td>{customer.customer_name}</td>
                <tr>
                  <th scope="col">Customer Email</th>
                  <td>{customer.customer_email}</td>
                </tr>
                <th scope="col">Phone Number</th>
                <td>{customer.customer_phone}</td>
                <tr>
                  <th scope="col">Address</th>
                  <td>{customer.customer_address}</td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </Col>
      ),
    });
  };

  return loading ? (
    <Loader title="Customers List" />
  ) : !customers ? (
    <NoPaymentHistory message={message} title="Customers History" />
  ) : (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8"></div>
      {/* Page content */}
      <Container className="mt--9" fluid>
        {/* Table */}

        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Customers List</h3>
                <Form
                  onSubmit={handleSubmit}
                  className="navbar-search navbar-search-light form-inline mr-3 d-none d-md-flex ml-lg-auto"
                >
                  <FormGroup className="mb-0">
                    <Input
                      type="search"
                      name="searchTerm"
                      placeholder="Enter utility reference number"
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <Button
                    className="m-3"
                    type="button"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    Search
                  </Button>
                  {searchActive && (
                    <Button
                      className="my-6"
                      onClick={handleSubmit}
                      color="primary"
                    >
                      Show All
                    </Button>
                  )}
                </Form>
              </CardHeader>
              <Table className="align-items-center table-flush">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Utility Reference Number</th>
                    <th scope="col">Account Number</th>
                    <th scope="col">Account Name</th>
                    <th scope="col">Bank Name</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {customers &&
                    customers.length &&
                    customers.map((customer) => {
                      return (
                        <tr key={customer.customer_id}>
                          <td>{customer.customer_ref}</td>
                          <td>{customer.account_number}</td>
                          <td>{customer.account_name}</td>
                          <td>{customer.bank}</td>

                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  onClick={() => handleViewMore(customer)}
                                >
                                  <Link to={`${baseUrl}/customers`}>
                                    View More
                                  </Link>
                                </DropdownItem>
                                <DropdownItem
                                  style={{
                                    cursor: 'pointer',
                                    color: '#5e72e4',
                                  }}
                                  onClick={() =>
                                    changeRoute(
                                      `${baseUrl}/customer-profile/${customer.customer_id}`
                                    )
                                  }
                                >
                                  {/* <Link
                                    to={
                                      `${this.state.baseUrl}/customer-profile/${customer.customer_id}`
                                    }
                                  > */}
                                  Edit
                                  {/* </Link> */}
                                </DropdownItem>
                                <DropdownItem
                                  style={{
                                    cursor: 'pointer',
                                    color: '#5e72e4',
                                  }}
                                  onClick={() =>
                                    changeRoute(
                                      `${baseUrl}/previous-profile/${customer.customer_ref}`
                                    )
                                  }
                                >
                                  {/* <Link
                                    to={`${this.state.baseUrl}/previous-profile/${customer.customer_ref}`}
                                  > */}
                                  Previous Customers
                                  {/* </Link> */}
                                </DropdownItem>
                                <DropdownItem
                                  style={{
                                    cursor: 'pointer',
                                    color: '#5e72e4',
                                  }}
                                >
                                  <Link
                                    to={{
                                      pathname: `${baseUrl}/customer-update/`,
                                      state: {
                                        customer_ref: customer.customer_ref,
                                      },
                                    }}
                                  >
                                    Update Customer Details
                                  </Link>
                                </DropdownItem>
                                {/* {this.state.baseUrl === '/utility' && <DropdownItem style={{cursor: 'pointer', color: '#5e72e4'}} onClick={() => this.changeRoute(`${this.state.baseUrl}/customer-payments/${customer.customer_ref}`)}> */}
                                <DropdownItem
                                  style={{
                                    cursor: 'pointer',
                                    color: '#5e72e4',
                                  }}
                                  onClick={() =>
                                    changeRoute(
                                      `${baseUrl}/customer-payments/${customer.customer_ref}`
                                    )
                                  }
                                >
                                  <Link
                                    to={{
                                      pathname: `${baseUrl}/customer-payments/${customer.customer_ref}`,
                                    }}
                                  >
                                    Customer Payments History
                                  </Link>
                                  {/* </DropdownItem>} */}
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  {!searchedDetailActive && (
                    <ListPagination
                      numberOfPages={pageNum}
                      currentPage={dataPage}
                      handleBackPage={handleBackPage}
                      handleNextPage={handleNextPage}
                      handleNewPage={handleNewPage}
                    />
                  )}
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Customer;
