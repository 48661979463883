import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/vendor/nucleo/css/nucleo.css';
import 'assets/vendor/@fortawesome/fontawesome-free/css/all.min.css';
import 'assets/scss/argon-dashboard-react.scss';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import { persistor } from './redux/Store';

ReactDOM.render(
  <div>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </div>,
  //
  document.getElementById('root')
);
