import React, { useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';
import { withRouter, Link, useHistory } from 'react-router-dom';
import swalReact from '@sweetalert/with-react';
import Loader from '../Loader';
import ListPagination from '../Pagination';
// reactstrap components
import {
    Card,
    CardHeader,
    CardFooter,
    Table,
    Container,
    Row,
    Form,
    FormGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    InputGroup,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Col,
    Button,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { userList, updateCurrentPage, updateActivateUser } from '../../../redux/actions/user';
import { capitalize } from '../../../utilitySevice/Helpers';

const Users = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const users = useSelector((state) => state.user.users);
    const baseURL = useSelector((state) => state.user.baseUrl);
    const dataPage = useSelector((state) => state.user.currentPage);
    const pageNum = useSelector((state) => state.user.numberOfPages);
    const [searchedDetailActive, setSearchDetailActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchName, setSearchName] = useState('');
    const sToken = sessionStorage.getItem('token');
    const sKey = CryptoJS.HmacSHA512(sessionStorage.getItem('token'),'123NDSx').toString();

    const handleNextPage = () => {
        dispatch(updateCurrentPage(dataPage + 1, history, () => { }));
    };

    const handleNewPage = (newPage) => {
        dispatch(updateCurrentPage(newPage, history, () => { }));
    };

    const handleBackPage = () => {
        dispatch(updateCurrentPage(dataPage - 1, history, () => { }));
    };
    useEffect(() => {
        setLoading(true)
        let data = {};
        data.passcode = sKey;
        data.token = sToken;
        data.action = 'get_users';
        data.page = dataPage;
        dispatch(userList(data, history, () => setLoading(false)));
    }, [dispatch, dataPage]);

    const handleSearchChange = e => {
        setSearchName(e.target.value);
    };


    const updateActiveState = (userId, status) => {
        setLoading(true);
        const data = {
            action: status,
            passcode: sKey,
            token: sToken,
            user_id: userId,
        }
        dispatch(updateActivateUser(data, history, () => setLoading(false)));
    };
      const handleSearch = async e => {
        setLoading(true)
          const data = {
          token: sToken,
          action: "get_users",
          passcode: sKey,
          user_name: searchName
        }
        dispatch(userList(data, history, () => setLoading(false)));
      };
  

    const handleViewMore = (user) => {
        swalReact({
            content: (
                <Col xl="14">
                    <Card className="shadow align-innertext">
                        <CardHeader className="border-0">
                            <Row className="align-items-center">
                                <div className="col">
                                    <h3 className="mb-0">User details</h3>
                                </div>
                            </Row>
                        </CardHeader>
                        <Table className="align-items-center table-flush">
                            <tbody>
                                <th scope="col">User Name</th>
                                <td>{user.user_name}</td>
                                <tr>
                                    <th scope="col">User Email</th>
                                    <td>{user.user_email}</td>
                                </tr>
                                <th scope="col">User Number</th>
                                <td>{user.user_phone}</td>
                                <tr>
                                    <th scope="col">User Role</th>
                                    <td>{user.user_role}</td>
                                </tr>
                                <tr>
                                    <th scope="col">User Status</th>
                                    <td>{user.user_status === "1" ? "active" : "inactive"}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Card>
                </Col>
            )
        });
    };



    return loading ? (
        <Loader title="Users List" />
    ) : !users.length ? (
        ''
    ) : (
        <>
            <div className="header bg-gradient-info pb-8 pt-5 pt-md-8"></div>
            <Container className="mt--9" fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <h3 className="mb-0">All Users List</h3>
                                <Form
                                    // onSubmit={handleSearch}
                                    className="navbar-search navbar-search-light form-inline mr-3 d-none d-md-flex ml-lg-auto"
                                >
                                    <FormGroup className="mb-0">
                                        <InputGroup className="input-group-alternative">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fas fa-search" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                name="searchName"
                                                placeholder="Enter user name"
                                                type="text"
                                                onChange={handleSearchChange}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                    <Button
                                        className="my-4"
                                        outline
                                        color="secondary"
                                        type="button"
                                          onClick={handleSearch}
                                        disabled={loading}
                                    >
                                        {loading ? (
                                            <span>
                                                <i className="fas fa-spinner fa-spin"></i>Please
                          wait...
                                            </span>
                                        ) : (
                                            "Search"
                                        )}
                                    </Button>
                                </Form>
                            </CardHeader>
                            <Table className="align-items-center table-flush">
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Phone</th>
                                        <th scope="col">Role</th>
                                        <th scope="col">Status</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.map(user => {
                                        return (
                                            <tr key={user.user_id}>
                                                <td>{user.user_name}</td>
                                                <td>{user.user_email}</td>
                                                <td>{user.user_phone}</td>
                                                <td>{capitalize(user.user_role)}</td>
                                                <td>{user.user_status === '1' ? "Active" : "Inactive"}</td>
                                                <td className="text-right">
                                                    <UncontrolledDropdown>
                                                        <DropdownToggle
                                                            className="btn-icon-only text-light"
                                                            role="button"
                                                            size="sm"
                                                            color=""
                                                            onClick={e => e.preventDefault()}
                                                        >
                                                            <i className="fas fa-ellipsis-v" />
                                                        </DropdownToggle>
                                                        <DropdownMenu
                                                            className="dropdown-menu-arrow"
                                                            right
                                                        >
                                                            <DropdownItem>
                                                                <Link to={`${baseURL}/user-update/${user.user_id}`} >
                                                                    Edit
                                  </Link>
                                                            </DropdownItem>

                                                            <DropdownItem
                                                                onClick={() => handleViewMore(user)}
                                                            >
                                                                <Link to={`${baseURL}/all-users`}>View</Link>
                                                            </DropdownItem>
                                                            {user.user_status === "1" ? <DropdownItem
                                                                onClick={() => updateActiveState(user.user_id, 'deactivate_user')}
                                                            >
                                                                <Link to={`${baseURL}/all-users`}>Deactivate</Link>
                                                            </DropdownItem> :
                                                                <DropdownItem
                                                                    onClick={() => updateActiveState(user.user_id, 'activate_user')}
                                                                >
                                                                    <Link to={`${baseURL}/all-users`}>Activate</Link>
                                                                </DropdownItem>
                                                            }
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                            <CardFooter className="py-4">
                                <nav aria-label="...">
                                    {!searchedDetailActive && (
                                        <ListPagination
                                            // key={this.state.numberOfPages--}
                                            numberOfPages={pageNum}
                                            currentPage={dataPage}
                                            handleBackPage={handleBackPage}
                                            handleNextPage={handleNextPage}
                                            handleNewPage={handleNewPage}
                                        />
                                    )}
                                </nav>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default Users;
