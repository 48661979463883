/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";

import { Container, Row, Col } from "reactstrap";

class AuthFooter extends React.Component {
  render() {
    return (
      <>
        <footer className="py-5">
          <Container>
            <Row className="align-items-center justify-content-xl-between">
              <Col xl="6">
                <div className="copyright text-center text-xl-left text-muted">
                © {new Date().getFullYear()}
                  <span
                  
                    className="font-weight-bold ml-1"
                  >
                    Adept
                  </span>
                </div>
              </Col>
              
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default AuthFooter;
