import React from 'react';
import {
    Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';


class  ListPagination extends React.Component {
    
      
       
    
    render()  {
        const { numberOfPages, currentPage, handleNextPage, handleBackPage, handleNewPage } = this.props;
    
        const pages = Array.from({length: numberOfPages}).map((element, index) => (1 + index));
    
        const displayPages = pages.filter((elem, index) => {
            
          return  (elem >= currentPage) || (numberOfPages - index <= 3 )
            
        }).splice(0, 3);
         
        return (
        <> 
            <Pagination
                          className="pagination justify-content-end mb-0" 
                          listClassName="justify-content-end mb-0"
                        >
                            <PaginationItem className="page-info">
                            
                            <div>Page {currentPage} of {`${numberOfPages}   `}</div>
                              
                           
                          </PaginationItem>

                            { (currentPage !== 1 ) &&
    
                          <PaginationItem className="">
                            <PaginationLink
                             
                             onClick={handleBackPage}
                              tabIndex="-1"
                            >
                              <i className="fas fa-angle-left" />
                              <span className="sr-only">Previous</span>
                            </PaginationLink>
                          </PaginationItem>
                            }
    
                            { displayPages.map((page, index) => {
    
                              return (
                              <PaginationItem key={index} className={page=== currentPage ? 'active' : ''}>
                               <PaginationLink
                              onClick={() => handleNewPage(page)}
                              
                            >
                              {page}
                            </PaginationLink>
                          </PaginationItem>)
    
                            }) }
                          
                          {(currentPage !== numberOfPages) &&
    
                          <PaginationItem>
                            <PaginationLink
                             
                              onClick={handleNextPage}
                            >
                              <i className="fas fa-angle-right" />
                              <span className="sr-only">Next</span>
                            </PaginationLink>
                          </PaginationItem>
                         }
                        </Pagination>
                        </>
                          
        )

    }
}

export default ListPagination;
