import { USER_LIST, START_LOADING, UPDATE_CURRENT_PAGE, SAVE_USER, REGISTER_USER, CLEAR_USER } from './actionTypes';
import Swal from 'sweetalert2';
import callApi from '../../utilitySevice/api';
import { handleError } from '../../utilitySevice/apiError';

export const startLoading = () => {
    return {
        type: START_LOADING,
    };
};
export const updateCurrentPage = (data, history, cb) => (dispatch) => {
    dispatch({
        type: UPDATE_CURRENT_PAGE,
        current_page: data
    })
    cb();
};
export const userList = (data, history, cb) => async (dispatch) => {
    dispatch(startLoading());
    try {
        const res = await callApi('/get_users.php', data, 'POST');
        if (res.status === "0") {
            const { items, current_page, total_pages } = res;
            dispatch({
                type: USER_LIST,
                users: res,
                items: items,
                current_page: current_page,
                total_pages: total_pages,
            })
            cb();
        } else {
            cb();
            handleError(res, history)
        }
    } catch (error) {
        cb();
        Swal.fire({
            icon: 'error',
            title: 'Unable to load users',
            text: 'Please check your internet and try again or contact support',
            position: 'center',
        });
    }
};
export const updateUser = (data, history, cb) => async (dispatch) => {
    dispatch(startLoading());
    try {
        const res = await callApi('/edit_user.php', data, 'POST');
        if (res.status === "0") {
            Swal.fire({
                icon: 'success',
                text: 'User updated successfully',
                showClass: {
                    popup: 'animated fadeInDown faster'
                },
                hideClass: {
                    popup: 'animated fadeOutUp faster'
                }
            });
            let type = sessionStorage.getItem('type');
            if (type == 'service_provider') {
                history.push("/utility/all-users");
            } else {
                history.push("/admin/all-users");
            }
        } else {
            cb();
            handleError(res, history)
        }
    } catch (error) {
        Swal.fire({
            icon: 'error',
            title: 'Update Failed',
            text: 'Unable to update user details',
        });
    }
};
export const updateActivateUser = (data, history, cb) => async (dispatch) => {
    dispatch(startLoading());
    try {
        const url = data.action == 'deactivate_user' ? '/deactivate_user.php' : '/activate_user.php'
        const res = await callApi(url, data, 'POST');
        if (res.status === "0") {
            window.location.reload()
        } else {
            cb();
            handleError(res, history)
        }
    } catch (error) {
        Swal.fire({
            icon: 'error',
            title: 'Update Failed',
            text: 'Unable to update user details',
        });
    }
};

export const getUser = (data, history, cb) => async (dispatch) => {
    try {
        const res = await callApi('/get_user.php', data, 'POST');
        if (res.status === "0") {
            dispatch({
                type: SAVE_USER,
                user: res,
            })
        } else {
            handleError(res, history)
        }
    } catch (error) {
        Swal.fire({
            icon: 'error',
            title: 'Update Failed',
            text: 'Unable to update agent details',
        });
    }
    cb();
};

export const userCreate = (data, history, cb) => async (dispatch) => {
    try {
        const res = await callApi('/register_user.php', data, 'POST');
        if (res.status === "0") {
            Swal.fire({
                icon: 'success',
                text: 'User created successfully',
                showClass: {
                    popup: 'animated fadeInDown faster'
                },
                hideClass: {
                    popup: 'animated fadeOutUp faster'
                }
            });
            dispatch({ type: REGISTER_USER, user: res });
            let type = sessionStorage.getItem('type');
            if (type == 'service_provider') {
                history.push("/utility/all-users");
            } else {
                history.push("/admin/all-users");
            }
        } else {
            cb();
            handleError(res, history)
        }
    } catch (err) {
        cb();
        return Swal.fire({
            icon: "error",
            title: "Registration Failed",
            text: "Unable to register user"
        });
    }
};

export const clearUpdateField = () => {
    return {
      type: CLEAR_USER
    };
  };

