import React, { useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';
import { withRouter, Link, useHistory } from 'react-router-dom';
import swalReact from '@sweetalert/with-react';
import Loader from '../Loader';
import ListPagination from '../Pagination';
// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Col,
  Button,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AgentList, updateCurrentPage } from '../../../redux/actions/agent';

const Agents = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const aResult = useSelector((state) => state.agent.aResult);
  const baseURL = useSelector((state) => state.agent.baseUrl);
  const dataPage = useSelector((state) => state.agent.currentPage);
  const pageNum = useSelector((state) => state.agent.numberOfPages);
  const [searchedDetailActive, setSearchDetailActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchName, setSearchName] = useState('');

  const handleNextPage = () => {
    dispatch(updateCurrentPage(dataPage + 1, history, () => {}));
  };

  const handleNewPage = (newPage) => {
    dispatch(updateCurrentPage(newPage, history, () => {}));
  };

  const handleBackPage = () => {
    dispatch(updateCurrentPage(dataPage - 1, history, () => {}));
  };
  useEffect(() => {
    setLoading(true)
    let data = {};
    const sToken = sessionStorage.getItem('token');
    const sKey = CryptoJS.HmacSHA512(
      sessionStorage.getItem('token'),
      '123NDSx'
    ).toString();
    data.passcode = sKey;
    data.token = sToken;
    data.action = 'get_agents';
    data.page = dataPage;
    dispatch(AgentList(data, history, () => setLoading(false)));
  }, [dispatch, dataPage]);

  const handleSearchChange = e => {
    setSearchName(e.target.value);
  };

  const handleSearch = async e => {
    setLoading(true)
    const sToken = sessionStorage.getItem('token');
    const sKey = CryptoJS.HmacSHA512(
      sessionStorage.getItem('token'),
      '123NDSx'
    ).toString();
      const data = {
      token: sToken,
      action: "get_agents",
      passcode: sKey,
      agent_name: searchName
    }
    dispatch(AgentList(data, history, () => setLoading(false)));
  };

const handleViewMore = agent => {
    swalReact({
      content: (
        <Col xl="14">
          <Card className="shadow align-innertext">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <div className="col">
                  <h3 className="mb-0">Agent details</h3>
                </div>
              </Row>
            </CardHeader>
            <Table className="align-items-center table-flush">
              <tbody>
                <th scope="col">Agent Name</th>
                <td>{agent.agent_name}</td>
                <tr>
                  <th scope="col">Agent Email</th>
                  <td>{agent.agent_email}</td>
                </tr>
                <th scope="col">Agent Number</th>
                <td>{agent.agent_phone}</td>           
              </tbody>
            </Table>
          </Card>
        </Col>
      )
    });
  };

  

    return loading ? (
      <Loader title="Agents List" />
    ) : !aResult.length ? (
      ''
    ) : (
      <>
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8"></div>
        <Container className="mt--9" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">All Agents List</h3>
                  <Form
                    onSubmit={handleSearch}
                    className="navbar-search navbar-search-light form-inline mr-3 d-none d-md-flex ml-lg-auto"
                  >
                    <FormGroup className="mb-0">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="searchName"
                          placeholder="Enter agent name"
                          type="text"
                          onChange={handleSearchChange}
                        />
                      </InputGroup>
                    </FormGroup>
                    <Button
                      className="my-4"
                      outline
                      color="secondary"
                      type="button"
                      onClick={handleSearch}
                      disabled={loading}
                    >
                      {loading ? (
                        <span>
                          <i className="fas fa-spinner fa-spin"></i>Please
                          wait...
                        </span>
                      ) : (
                        "Search"
                      )}
                    </Button>
                  </Form>
                </CardHeader>
                <Table className="align-items-center table-flush">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Agent Name</th>
                      <th scope="col">Agent Email</th>
                      <th scope="col">Agent Phone</th>
                      <th scope="col"></th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {aResult.map(agent => {
                      return (
                        <tr key={agent.agent_id}>
                          <td>{agent.agent_name}</td>
                          <td>{agent.agent_email}</td>
                          <td>{agent.agent_phone}</td>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                role="button"
                                size="sm"
                                color=""
                                onClick={e => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem>
                                  <Link
                                    to={
                                      `${baseURL}/agent-update/${agent.agent_id}`
                                    }
                                  >
                                    Edit
                                  </Link>
                                </DropdownItem>

                                <DropdownItem
                                  onClick={() => handleViewMore(agent)}
                                >
                                  <Link to={`${baseURL}/agents`}>View</Link>
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    {!searchedDetailActive && (
                      <ListPagination
                        // key={this.state.numberOfPages--}
                        numberOfPages={pageNum}
                        currentPage={dataPage}
                        handleBackPage={handleBackPage}
                        handleNextPage={handleNextPage}
                        handleNewPage={handleNewPage}
                      />
                    )}
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  };

export default Agents;
