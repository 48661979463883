import {
  CUSTOMER_PAYMENT_HISTORY,
  CUSTOMER_PAYMENT_HISTORY_EXPORT,
  GET_SETTLEMENTS,
  GET_SETTLEMENTS_EXPORT,
  UPDATE_CURRENT_PAGE
} from '../actions/actionTypes';

const initialState = {
  paymentsLoading: false,
  customer_reference: '',
  searched: '',
  payments: [],
  modal: false,
  startDate: '',
  endDate: '',
  response: {},
  clear: false,
  numberOfPages: 1,
  currentPage: 1,
  commission: '',
  settlements: [],
  paymentsExport:null,
  settlementsExport:null
};

export const HistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMER_PAYMENT_HISTORY:
      return {
        ...state,
        payments: action.items,
        commission: action.commission_earned,
        numberOfPages: action.total_pages,
        currentPage: action.current_page,
        paymentsLoading: false,
      };
      case CUSTOMER_PAYMENT_HISTORY_EXPORT:
        return {
          ...state,
          paymentsExport: action.payload,
          paymentsLoading: false,
        };
    case GET_SETTLEMENTS:
      return {
        ...state,
        settlements: action.items,
        currentPage: action.current_page,
        numberOfPages: action.numberOfPages,
      };
      case GET_SETTLEMENTS_EXPORT:
        return {
          ...state,
          settlementsExport: action.payload
        };
      case UPDATE_CURRENT_PAGE:
        return {
          ...state,
          currentPage: action.current_page
        }
    default:
      return state;
  }
};
